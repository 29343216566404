import React, { memo, useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Paper,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import { useParams } from "react-router-dom";
import { TableBodyElement } from "../../../components/TableBody/TableBodyElement";
import { TableHeadElement } from "../../../components/TableBody/TableHeadElement";
import {
  ColumnsType,
  Order,
  PageMeta,
  SortingData,
} from "../../../types/CommonTypes";
import ConfirmBox from "../../../components/ConfirmBox/ConfirmBox";
import { useGetMachineInfoByIdQuery } from "../../../redux-services";
import AddEditDocument from "./AddEditDocument";
import {
  useDeleteMachineDocMutation,
  useGetAllMachineDocsQuery,
} from "../../../redux-services/api/MachineDocApi";

interface PreviousImage {
  path: string;
  fullUrl: string;
  expiry_date: number;
  insurance_amount: number;
  encodedUrl: [];
}

const tableColumns: ColumnsType[] = [
  {
    id: "id",
    sort: false,
    label: "S.No.",
  },
  {
    id: "document_type",
    sort: true,
    label: "Document Type",
  },
  {
    id: "createdAt",
    sort: true,
    label: "Created At",
  },

  {
    id: "action",
    sort: false,
    label: "Action",
    style: { width: "15%" },
  },
];

const initialOrder: Order = process.env.REACT_APP_ORDER as Order;

const MachineDocument = () => {
  const initialState: PageMeta = {
    page: 1,
    per_page: Number(process.env.REACT_APP_PER_PAGE),
    searchText: "",
    order: process.env.REACT_APP_ORDER,
    order_by: process.env.REACT_APP_ORDER_BY,
  };

  const { id } = useParams<string>();

  const [orderBy, setOrderBy] = useState<keyof SortingData>(
    process.env.REACT_APP_ORDER_BY as keyof SortingData
  );
  const [order, setOrder] = useState<Order>(initialOrder);
  const [shiftLogsList, setShiftLogsList] = useState<PageMeta>(initialState);
  const [isClose, setIsClose] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [machineDocumentEditId, setMachineDocumentEditId] =
    useState<string>("");
  const [Id, setId] = useState<string>("");
  const [isActive, setIsActive] = useState<boolean>(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [previousImages, setPreviousImages] = useState<PreviousImage[]>([]);
  const [createdDate, setCreatedDate] = useState<number | null>(null);
  const [open, setOpen] = useState<boolean>(false);

  const { data: getData } = useGetMachineInfoByIdQuery(id, {
    skip: !id,
  });
  useEffect(() => {
    if (getData?.status === 200) {
      const getDataResponse = getData?.response;
      let data = getDataResponse;
      setIsActive(data.is_active);
    }
  }, [getData]);

  const [deleteMachineDoc] = useDeleteMachineDocMutation();

  const { data } = useGetAllMachineDocsQuery(id, {
    skip: !id,
  });

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof SortingData
  ) => {
    const isAsc = orderBy === property && order === "asc";
    const newOrder = isAsc ? "desc" : "asc";
    setOrder(newOrder);
    setOrderBy(property);
    setShiftLogsList({ ...shiftLogsList, order: newOrder, order_by: property });
  };

  const createSortHandler = (
    event: React.MouseEvent<unknown>,
    property: keyof SortingData
  ) => {
    if (property !== ("action" as keyof SortingData)) {
      handleRequestSort(event, property);
    }
  };

  const confirmBoxClose = (res: boolean) => {
    console.log(res);
    setIsClose(res);
    if (res) {
      setIsClose(false);
      deleteMachineDoc(Id);
    }
  };

  const handleViewDocument = (id: string) => {
    const document = data?.response?.data.find((doc: any) => doc._id === id);
    setCreatedDate(document.createdAt);
    setPreviousImages(document.previous_images || []);
    setOpenDialog(true);
  };
  const handleClose = () => {
    setOpenDialog(false);
  };
  const handleViewDownload = (documentId: any) => {
    const documentIndex = data?.response?.data.findIndex(
      (doc: { _id: any }) => doc._id === documentId
    );

    if (documentIndex !== -1) {
      const url = data?.response.encodedUrl[documentIndex];
      window.open(url, "_blank");
    } else {
      console.log("Document not found");
    }
  };
  const handlePreviousUrlDownload = (documentId: PreviousImage) => {
    const prev = data?.response?.data;
    const filteredData = prev?.filter(
      (item: { previous_images: string | any[] }) =>
        item.previous_images && item.previous_images.length > 0
    );
    let matchingIndex = -1;
    filteredData.some((item: any, index: any) => {
      const exists = item.previous_images.some((image: any) => {
        return (
          image.path === documentId.path &&
          image.fullUrl === documentId.fullUrl &&
          image.expiry_date === documentId.expiry_date &&
          image.insurance_amount === documentId.insurance_amount
        );
      });
      if (exists) {
        matchingIndex = index;
        return true;
      }
      return false;
    });

    if (matchingIndex !== -1) {
      const encodedUrl = data?.response?.prevEncodedUrl[matchingIndex];
      window.open(encodedUrl, "_blank");
    } else {
      console.log("Document not found.");
    }
  };

  const handleShowMessage = () => {
    setSnackbarOpen(true);
    setTimeout(() => {
      setSnackbarOpen(false);
    }, 3000);
  };

  const mappedData =
    data?.response?.data.map((data: any) => ({
      document_type: data.document_type || "",
      createdAt: data.createdAt || "",
      _id: data._id || "",
      previous_images: data?.previous_images ?? [],
    })) || [];

  const requiredDocumentTypes = [
    "Insurance",
    "Vehicle State Tax",
    "Vehicle Paper",
    "Vehicle India Permit",
    "Loan Paper",
    "Vehicle RC",
  ];

  const allDocumentsPresent = requiredDocumentTypes.every((type) =>
    mappedData.some(
      (doc: { document_type: string }) =>
        doc.document_type.trim() === type.trim()
    )
  );

  return (
    <>
      <div>
        <Paper className="form-outers">
          <Toolbar>
            <Typography
              sx={{ flex: "1 1 40%" }}
              variant="h5"
              id="tableTitle"
              className="inner-headings"
              component="div"
              onClick={() => setOpen(true)}
            >
              Machine Documents
            </Typography>
            <div className="flex-boxinner">
              <Typography sx={{ flex: "1 1 auto" }}></Typography>
              <Tooltip
                title={
                  allDocumentsPresent
                    ? "Documents already added"
                    : "Add Machine Document"
                }
                arrow
                className="me-1 mt-1"
              >
                <span>
                  <Button
                    className="secondary-btn"
                    onClick={() => {
                      setShowModal(true);
                      handleShowMessage();
                    }}
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={allDocumentsPresent}
                  >
                    Add Machine Document
                  </Button>
                </span>
              </Tooltip>
              <Snackbar
                open={snackbarOpen && !isActive}
                autoHideDuration={3000}
                onClose={() => setSnackbarOpen(false)}
                message="Please activate this machine to add work logs."
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                style={{ marginTop: "140px" }}
              />
            </div>
          </Toolbar>

          <TableContainer component={Paper} className="form-outers">
            <Table
              sx={{ minWidth: 650 }}
              size="small"
              aria-label="a dense table"
              className="table-outers"
            >
              <TableHead>
                <TableRow>
                  <TableHeadElement
                    setHead={tableColumns ?? []}
                    order={order}
                    orderBy={orderBy}
                    sortFn={(event, id: string) =>
                      createSortHandler(event, id as keyof SortingData)
                    }
                  />
                </TableRow>
              </TableHead>
              <TableBody>
                <TableBodyElement
                  selectedColumns={["document_type", "createdAt"]}
                  setData={mappedData}
                  editFn={(id) => {
                    setMachineDocumentEditId(id);
                    setShowModal(true);
                  }}
                  deleteFn={(id) => {
                    setIsClose(true);
                    setId(id);
                  }}
                  viewDownloadFn={handleViewDownload}
                  viewDocumentFn={(id) => {
                    handleViewDocument(id);
                  }}
                  pageData={{
                    limit: Number(process.env.REACT_APP_PER_PAGE),
                    page: data?.response?.page || 1,
                  }}
                />
                <Dialog open={openDialog} onClose={handleClose}>
                  <DialogTitle>Previous Details</DialogTitle>

                  <DialogContent>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>Expiry Date</TableCell>
                          <TableCell>Amount</TableCell>
                          <TableCell>Download Invoice</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {previousImages?.map((image, index) => (
                          <TableRow key={image.path || `img-${index}`}>
                            <TableCell>
                              {new Date(
                                image.expiry_date * 1000
                              ).toLocaleDateString()}
                            </TableCell>
                            <TableCell>{image.insurance_amount}</TableCell>
                            <TableCell>
                              <IconButton
                                onClick={() => handlePreviousUrlDownload(image)}
                              >
                                <DownloadIcon />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleClose}>Close</Button>
                  </DialogActions>
                </Dialog>
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
        <ConfirmBox
          title="Machine Document Delete Confirmation"
          message="Are you sure, you want to delete this Machine Document?"
          open={isClose}
          onClose={confirmBoxClose}
        />
      </div>
      {showModal && isActive && (
        <AddEditDocument
          id={machineDocumentEditId}
          showModal={showModal}
          setShowModal={setShowModal}
          setMachineEntryEditId={setMachineDocumentEditId}
          paramId={id}
        />
      )}
    </>
  );
};

export default memo(MachineDocument);
