import React from "react";
import { Link, useLocation } from "react-router-dom";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
 
const AppSidebarNav = ({ items, CloseSidebar }: any) => {
  const location = useLocation();
 
  return (
    <ul className="sidebar-list">
      {items?.map((item: any, index: number) => {
        return (
          <div
            className={`sidebar-list-item ${
              location?.pathname?.includes(item.to) ? "sidebar-list-active" : ""
            }`}
            key={item}
            role="button"
            onClick={() => {
              CloseSidebar();
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter" || e.key === " ") {
                CloseSidebar();
              }
            }}
          >
            {item?.child?.length ? (
              <>
                <Link
                  to={item?.to}
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div>
                    {item?.icon} {item?.name}{" "}
                  </div>
                  {location?.pathname?.includes(item.to) ? (
                    <ArrowDropUpIcon />
                  ) : (
                    <ArrowDropDownIcon />
                  )}
                </Link>
                {location?.pathname?.includes(item.to) &&
                  item?.child?.map((subMenu: any) => (
                    <li
                      className={`sidebar-list-item subMenu ${
                        subMenu.to === location?.pathname
                          ? "sidebar-list-active"
                          : ""
                      }`}
                      key={subMenu?.name}
                    >
                      <Link to={subMenu?.to}>
                        {subMenu?.icon} {subMenu?.name}
                      </Link>
                    </li>
                  ))}
              </>
            ) : (
              <Link to={item?.to} className={`navLink`}>
                <div>
                  {item?.icon} {item?.name}
                </div>
              </Link>
            )}
          </div>
        );
      })}
    </ul>
  );
};
 
export default AppSidebarNav;