import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Container,
  Divider,
  Grid,
  TextField,
} from "@mui/material";
import React, { FC, FormEvent, useEffect, useState } from "react";
import {
  useCreateChartMutation,
  useGetChartQuery,
  useUpdateChartMutation,
} from "../../../redux-services/api/RateChartApi";

export interface UserState {
  road_side_fixed_rate: number;
  road_side_additional_rate: number;
  bore_area_fixed_rate: number;
  bore_area_additional_rate: number;
  distance_limit: number;
}

const RateChartCard: FC<{ siteDetail: { id: string; name: string } }> = ({
  siteDetail,
}) => {
  const initialState: UserState = {
    road_side_fixed_rate: 0,
    road_side_additional_rate: 0,
    bore_area_fixed_rate: 0,
    bore_area_additional_rate: 0,
    distance_limit: 0,
  };

  const [formData, setFormData] = useState<UserState>(initialState);
  const [formErrors, setFormErrors] = useState<Record<string, string | null>>(
    {}
  );
  const {
    data: chartData
  } = useGetChartQuery(siteDetail.id, { skip: !siteDetail.id });
  const [
    createChart
  ] = useCreateChartMutation();
  const [
    updateChart
  ] = useUpdateChartMutation();

  useEffect(() => {
    if (chartData?.status === 200 && chartData?.response) {
      setFormData({
        road_side_fixed_rate: chartData.response.road_side_fixed_rate ?? 0,
        road_side_additional_rate:
          chartData.response.road_side_additional_rate ?? 0,
        bore_area_fixed_rate: chartData.response.bore_area_fixed_rate ?? 0,
        bore_area_additional_rate:
          chartData.response.bore_area_additional_rate ?? 0,
        distance_limit: chartData.response.distance_limit ?? 0,
      });
    }
  }, [chartData]);

  const validateForm = () => {
    const errors: Record<string, string | null> = {};

    if (formData.distance_limit === 0)
      errors.distance_limit = "Distance Limit cannot be zero.";
    if (formData.road_side_fixed_rate === 0)
      errors.road_side_fixed_rate = "Road Side Fixed Rate cannot be zero.";
    if (formData.road_side_additional_rate === 0)
      errors.road_side_additional_rate =
        "Road Side Additional Rate cannot be zero.";
    if (formData.bore_area_fixed_rate === 0)
      errors.bore_area_fixed_rate = "Bore Area Fixed Rate cannot be zero.";
    if (formData.bore_area_additional_rate === 0)
      errors.bore_area_additional_rate =
        "Bore Area Additional Rate cannot be zero.";

    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();

    if (!validateForm()) {
      return;
    }

    const data = {
      site_id: siteDetail.id,
      road_side_fixed_rate: formData.road_side_fixed_rate,
      road_side_additional_rate: formData.road_side_additional_rate,
      bore_area_fixed_rate: formData.bore_area_fixed_rate,
      bore_area_additional_rate: formData.bore_area_additional_rate,
      distance_limit: formData.distance_limit,
    };

    if (chartData?.status === 200) {
      updateChart({ siteId: siteDetail.id, data });
    } else {
      createChart(data);
    }
  };
  const handleReset = () => {
    setFormData(initialState);
    setFormErrors({});
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    const newValue = value === "" ? 0 : Number(value);

    setFormData((prevData) => ({
      ...prevData,
      [name]: newValue,
    }));

    if (newValue !== 0) {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        [name]: null,
      }));
    }
  };

  return (
    <Container maxWidth={false} className="p-0">
      <Card className="form-outers" elevation={3}>
        <CardHeader className="inner-headings" title={"Rate Chart Card"} />
        <CardContent>
          <Divider sx={{ marginBottom: 3 }} />
          <form onSubmit={handleSubmit}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={12}>
                <TextField
                  required
                  fullWidth
                  id="distance_limit"
                  label="Distance Limit"
                  name="distance_limit"
                  size="small"
                  autoComplete="name"
                  type="number"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={formData.distance_limit}
                  onChange={handleChange}
                  error={!!formErrors.distance_limit}
                  helperText={formErrors.distance_limit}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  required
                  fullWidth
                  id="road_side_fixed_rate"
                  label="Road Side Fixed Rate"
                  name="road_side_fixed_rate"
                  size="small"
                  type="number"
                  autoComplete="name"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={formData.road_side_fixed_rate}
                  onChange={handleChange}
                  error={!!formErrors.road_side_fixed_rate}
                  helperText={formErrors.road_side_fixed_rate}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  required
                  fullWidth
                  id="road_side_additional_rate"
                  label={`Road Side Additional Rate ${
                    formData.distance_limit !== 0
                      ? "After " + formData?.distance_limit + "Km"
                      : ""
                  } (per/km) `}
                  name="road_side_additional_rate"
                  type="number"
                  size="small"
                  autoComplete="name"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={formData.road_side_additional_rate}
                  onChange={handleChange}
                  error={!!formErrors.road_side_additional_rate}
                  helperText={formErrors.road_side_additional_rate}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  required
                  fullWidth
                  id="bore_area_fixed_rate"
                  label="Bore Area Fixed Rate"
                  name="bore_area_fixed_rate"
                  size="small"
                  autoComplete="name"
                  type="number"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={formData.bore_area_fixed_rate}
                  onChange={handleChange}
                  error={!!formErrors.bore_area_fixed_rate}
                  helperText={formErrors.bore_area_fixed_rate}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  required
                  fullWidth
                  id="bore_area_additional_rate"
                  label={`Bore Area Additional Rate ${
                    formData.distance_limit !== 0
                      ? "After " + formData?.distance_limit + "Km"
                      : ""
                  } (per/km) `}
                  name="bore_area_additional_rate"
                  size="small"
                  autoComplete="name"
                  type="number"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={formData.bore_area_additional_rate}
                  onChange={handleChange}
                  error={!!formErrors.bore_area_additional_rate}
                  helperText={formErrors.bore_area_additional_rate}
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  className="save-btn"
                  style={{ marginRight: "10px", marginBottom: "20px" }}
                >
                  {chartData && Object.keys(chartData).length > 0
                    ? "Submit"
                    : "Create"}
                </Button>
                <Button
                  variant="contained"
                  className="close-btn"
                  color="secondary"
                  onClick={handleReset}
                  style={{ marginRight: "10px", marginBottom: "20px" }}
                >
                  Reset
                </Button>
              </Grid>
            </Grid>
          </form>
        </CardContent>
      </Card>
    </Container>
  );
};

export default RateChartCard;
