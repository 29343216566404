import React, { FC, memo, useEffect, useState } from "react";
import {
  Box,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  Paper,
  Toolbar,
  Typography,
  Grid,
  SelectChangeEvent,
} from "@mui/material";
import { PageMeta } from "../../../../types/CommonTypes";
import { useParams } from "react-router-dom";
import {
  useEarningExpenditureReportListQuery,
  useEmpAttendanceMonthlyReportListQuery,
  useMachineMonthlyReportListQuery,
} from "../../../../redux-services";
import { DateRangePicker } from "rsuite";
import "rsuite/dist/rsuite-no-reset.min.css";
import subDays from "date-fns/subDays";
import startOfWeek from "date-fns/startOfWeek";
import endOfWeek from "date-fns/endOfWeek";
import addDays from "date-fns/addDays";
import startOfMonth from "date-fns/startOfMonth";
import endOfMonth from "date-fns/endOfMonth";
import addMonths from "date-fns/addMonths";
import { DefaultizedPieValueType } from "@mui/x-charts/models";
import { PieChart, pieArcLabelClasses } from "@mui/x-charts/PieChart";
import MachineMonthlyReport from "./MachineMonthlyReport";
import EmployeeAttendanceReport from "./EmployeeAttendanceReport";
import EarningExpenditureReport from "./EarnExpense/EarningExpenditureReport";

interface RenderContentProps {
  monthlyReportList: any;
  dateRange: any;
  handleDateChange: (range: any) => void;
  selectChange: (event: SelectChangeEvent<any>) => void;
  EmpAttendanceReportData: any;
  refetchEmpAttendance: () => void;
  MachineReportData: any;
  refetchMachineReport: () => void;
  EarningExpenditureReportData: any;
  refetchEarningExpense: () => void;
  earningPieData: any;
  expensePieData: any;
  tripPiedata: any;
  fuelPiedata: any;
  sizing: any;
}

const predefinedRanges = [
  {
    label: "Today",
    value: [new Date(), new Date()],
    placement: "left",
  },
  {
    label: "Yesterday",
    value: [addDays(new Date(), -1), addDays(new Date(), -1)],
    placement: "left",
  },
  {
    label: "This week",
    value: [startOfWeek(new Date()), endOfWeek(new Date())],
    placement: "left",
  },
  {
    label: "Last 7 days",
    value: [subDays(new Date(), 6), new Date()],
    placement: "left",
  },
  {
    label: "Last 30 days",
    value: [subDays(new Date(), 29), new Date()],
    placement: "left",
  },
  {
    label: "This month",
    value: [startOfMonth(new Date()), new Date()],
    placement: "left",
  },
  {
    label: "Last month",
    value: [
      startOfMonth(addMonths(new Date(), -1)),
      endOfMonth(addMonths(new Date(), -1)),
    ],
    placement: "left",
  },
  {
    label: "This year",
    value: [new Date(new Date().getFullYear(), 0, 1), new Date()],
    placement: "left",
  },
  {
    label: "Last year",
    value: [
      new Date(new Date().getFullYear() - 1, 0, 1),
      new Date(new Date().getFullYear(), 0, 0),
    ],
    placement: "left",
  },
  {
    label: "All time",
    value: [new Date(new Date().getFullYear() - 1, 0, 1), new Date()],
    placement: "left",
  },
  {
    label: "Last week",
    closeOverlay: false,
    value: (value: any) => {
      const [start = new Date()] = value || [];
      return [
        addDays(startOfWeek(start, { weekStartsOn: 0 }), -7),
        addDays(endOfWeek(start, { weekStartsOn: 0 }), -7),
      ];
    },
    appearance: "default",
  },
  {
    label: "Next week",
    closeOverlay: false,
    value: (value: any) => {
      const [start = new Date()] = value || [];
      return [
        addDays(startOfWeek(start, { weekStartsOn: 0 }), 7),
        addDays(endOfWeek(start, { weekStartsOn: 0 }), 7),
      ];
    },
    appearance: "default",
  },
];

export const renderContent = ({
  monthlyReportList,
  dateRange,
  handleDateChange,
  selectChange,
  EmpAttendanceReportData,
  refetchEmpAttendance,
  MachineReportData,
  refetchMachineReport,
  EarningExpenditureReportData,
  refetchEarningExpense,
  earningPieData,
  expensePieData,
  tripPiedata,
  fuelPiedata,
  sizing,
}: RenderContentProps) => (
  <div>
    <Paper className="form-outers">
      <Grid container spacing={0}>
        <Grid
          item
          md={
            ["attendance", "None", "earning_expense"]?.includes(
              monthlyReportList?.option
            )
              ? 12
              : 9
          }
        >
          <Toolbar className="pe-0">
            <Typography
              sx={{ flex: "1 1 40%" }}
              variant="h5"
              id="tableTitle"
              component="div"
              className="inner-headings"
            >
              {monthlyReportList?.option === "None" && "Monthly Report Detail"}
              {monthlyReportList?.option === "machine" &&
                "Machine Monthly Report"}
              {monthlyReportList?.option === "attendance" &&
                "Employee Attendance Report"}
              {monthlyReportList?.option === "earning_expense" &&
                "Earning Expenditure Report"}
            </Typography>
            <div className="flex-boxinner worklogs">
              <Typography
                sx={{ flex: "1 1 auto" }}
                className="datepicker-outer"
              >
                <DateRangePicker
                  ranges={predefinedRanges as any}
                  placeholder="Select Date Range"
                  showOneCalendar
                  onChange={handleDateChange}
                  style={{ width: "100%" }}
                  className="dateselecttype"
                  defaultValue={[dateRange?.startDate, dateRange?.endDate]}
                />
                <Box sx={{ minWidth: 270, mr: 2 }}>
                  <FormControl fullWidth size="small">
                    <InputLabel id="demo-simple-select-label">
                      Select Option *
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={
                        monthlyReportList.option !== ""
                          ? monthlyReportList.option
                          : "None"
                      }
                      label="Select Option"
                      required
                      onChange={selectChange}
                    >
                      <MenuItem value={"None"}>None</MenuItem>
                      <MenuItem value={"machine"}>Machinery Report</MenuItem>
                      <MenuItem value={"attendance"}>
                        Employee Attendance Report
                      </MenuItem>
                      <MenuItem value={"earning_expense"}>
                        Earning Expenditure Report
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Box>
              </Typography>
            </div>
          </Toolbar>
          {monthlyReportList?.option === "attendance" &&
          EmpAttendanceReportData?.response?.length > 0 &&
          monthlyReportList?.option !== "None" ? (
            <EmployeeAttendanceReport
              EmpAttendanceReportData={EmpAttendanceReportData}
              refetchEmpAttendance={refetchEmpAttendance}
            />
          ) : (
            ""
          )}
          {monthlyReportList?.option === "machine" &&
          MachineReportData?.response?.length > 0 &&
          monthlyReportList?.option !== "None" ? (
            <MachineMonthlyReport
              MachineReportData={MachineReportData}
              refetchMachineReport={refetchMachineReport}
            />
          ) : (
            ""
          )}
          {monthlyReportList?.option === "earning_expense" &&
          EarningExpenditureReportData?.response &&
          monthlyReportList?.option !== "None" ? (
            <EarningExpenditureReport
              earning={EarningExpenditureReportData?.response?.earning}
              expense={EarningExpenditureReportData?.response?.expense}
              refetchEarningExpense={refetchEarningExpense}
            />
          ) : (
            ""
          )}
        </Grid>
        {monthlyReportList?.option === "earning_expense" && (
          <Grid item md={12}>
            {EarningExpenditureReportData?.status === 200 &&
            monthlyReportList?.option !== "None" ? (
              <Box className="pieChart-outer">
                <Typography
                  variant="h5"
                  id="tableTitle"
                  component="div"
                  className="inner-headings"
                >
                  Graphical Reports
                </Typography>
                <Box className="PieChartone border-bottom">
                  <PieChart
                    series={[
                      {
                        outerRadius: 80,
                        data: earningPieData,
                        arcLabel: (params: DefaultizedPieValueType) => {
                          if (params.value === 0) {
                            return "0";
                          } else {
                            return `${params.value}`;
                          }
                        },
                      },
                    ]}
                    sx={{
                      [`& .${pieArcLabelClasses.root}`]: {
                        fill: "white",
                        fontSize: 14,
                      },
                    }}
                    {...sizing}
                  />
                  <Typography variant="body1">Earning Chart</Typography>
                </Box>
                <Box className="PieChartone">
                  <PieChart
                    series={[
                      {
                        outerRadius: 80,
                        data: expensePieData,
                        arcLabel: (params: DefaultizedPieValueType) => {
                          if (params.value === 0) {
                            return "0";
                          } else {
                            return `${params.value}`;
                          }
                        },
                      },
                    ]}
                    sx={{
                      [`& .${pieArcLabelClasses.root}`]: {
                        fill: "white",
                        fontSize: 14,
                      },
                    }}
                    {...sizing}
                  />
                  <Typography variant="body1">Expense Chart</Typography>
                </Box>
              </Box>
            ) : (
              ""
            )}
          </Grid>
        )}
        {monthlyReportList?.option === "machine" && (
          <Grid item md={MachineReportData?.response?.length === 0 ? 0 : 3}>
            {MachineReportData?.response?.length > 0 &&
            monthlyReportList?.option !== "None" ? (
              <Box className="pieChart-outer">
                <Typography
                  variant="h5"
                  id="tableTitle"
                  component="div"
                  className="inner-headings"
                >
                  Graphical Reports
                </Typography>
                <Box className="PieChartone border-bottom">
                  <PieChart
                    series={[
                      {
                        outerRadius: 80,
                        data: tripPiedata,
                        arcLabel: (params: DefaultizedPieValueType) => {
                          if (params.value === 0) {
                            return "0";
                          } else {
                            return `${params.total_measurement ?? 0}`;
                          }
                        },
                      },
                    ]}
                    sx={{
                      [`& .${pieArcLabelClasses.root}`]: {
                        fill: "white",
                        fontSize: 14,
                      },
                    }}
                    {...sizing}
                  />
                  <Typography variant="body1">Machine Running Chart</Typography>
                </Box>
                <Box className="PieChartone">
                  <PieChart
                    series={[
                      {
                        outerRadius: 80,
                        data: fuelPiedata,
                        arcLabel: (params: DefaultizedPieValueType) => {
                          if (params.value === 0) {
                            return "0";
                          } else {
                            return `${params.value}`;
                          }
                        },
                      },
                    ]}
                    sx={{
                      [`& .${pieArcLabelClasses.root}`]: {
                        fill: "white",
                        fontSize: 14,
                      },
                    }}
                    {...sizing}
                  />
                  <Typography variant="body1">
                    Fuel Consumption Chart
                  </Typography>
                </Box>
              </Box>
            ) : (
              ""
            )}
          </Grid>
        )}
      </Grid>
    </Paper>
  </div>
);

const MonthlyReport: FC<{ siteDetail: { id: string; name: string } }> = () => {
  const initialState: PageMeta = {
    page: 1,
    per_page: Number(process.env.REACT_APP_PER_PAGE),
    searchText: "",
    order: process.env.REACT_APP_ORDER,
    order_by: process.env.REACT_APP_ORDER_BY,
    startDate: 0,
    endDate: 0,
    option: "None",
  };
  const { id } = useParams();
  const [tripPiedata, setTripPieData] = useState([
    { label: "Category 1", value: 25 },
    { label: "Category 2", value: 30 },
    { label: "Category 3", value: 45 },
  ]);
  const [fuelPiedata, setFuelPieData] = useState([
    { label: "Category 1", value: 25 },
    { label: "Category 2", value: 30 },
    { label: "Category 3", value: 45 },
  ]);

  const [earningPieData, setEarningPieData] = useState([
    { label: "Category 1", value: 25 },
  ]);
  const [expensePieData, setExpensePieData] = useState([
    { label: "Category 1", value: 25 },
  ]);

  const sizing = {
    margin: { right: 5 },
    width: 200,
    height: 200,
    legend: { hidden: true },
  };

  const [dateRange, setDateRange] = useState({
    startDate: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
    endDate: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0),
  });
  const [monthlyReportList, setMonthlyReportList] =
    useState<PageMeta>(initialState);

  const { data: MachineReportData, refetch: refetchMachineReport } =
    useMachineMonthlyReportListQuery(
      {
        startDate: monthlyReportList?.startDate ?? 1719772200000,
        endDate: monthlyReportList?.endDate ?? 1722450599059,
        site_id: id ?? "",
      },
      { skip: monthlyReportList?.option === "machine" }
    );

  const { data: EmpAttendanceReportData, refetch: refetchEmpAttendance } =
    useEmpAttendanceMonthlyReportListQuery(
      {
        startDate: monthlyReportList?.startDate ?? 1719772200000,
        endDate: monthlyReportList?.endDate ?? 1722450599059,
        site_id: id ?? "",
      },
      { skip: monthlyReportList?.option === "attendance" }
    );

  const { data: EarningExpenditureReportData, refetch: refetchEarningExpense } =
    useEarningExpenditureReportListQuery(
      {
        startDate: monthlyReportList?.startDate ?? 1719772200000,
        endDate: monthlyReportList?.endDate ?? 1722450599059,
        site_id: id ?? "",
      },
      { skip: monthlyReportList?.option === "earning_expense" }
    );

  useEffect(() => {
    const today = new Date();
    const startOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
    const endOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);
    let start = startOfMonth?.setHours(0, 0, 0, 0);
    let end = endOfMonth?.setHours(23, 59, 59, 59);
    setMonthlyReportList({
      ...monthlyReportList,
      startDate: start,
      endDate: end,
    });
  }, []);

  useEffect(() => {
    if (MachineReportData?.status === 200) {
      let data = [];
      let fuelData = [];
      const response = MachineReportData?.response || [];
      for (let item of response) {
        if (item?.measurement !== 0) {
          data.push({
            label: item?.machine_name ?? "",
            value: item?.measurement ?? 0,
            total_measurement: item?.total_measurement ?? "",
          });
        }
        if (item?.total_fuel_consumption !== 0) {
          fuelData.push({
            label: item?.machine_name ?? "",
            value: item?.total_fuel_consumption ?? 0,
          });
        }
      }
      if (data.length > 0) {
        setTripPieData(data);
      } else {
        setTripPieData([{ label: "No Record Found", value: 100 }]);
      }

      if (fuelData.length > 0) {
        setFuelPieData(fuelData);
      } else {
        setFuelPieData([{ label: "No Record Found", value: 100 }]);
      }
    }
  }, [MachineReportData]);

  useEffect(() => {
    if (EarningExpenditureReportData?.status === 200) {
      let earningData = [];
      let expenseData = [];
      let item = EarningExpenditureReportData?.response;
      earningData.push({
        label: "Earning Report",
        value: item?.earning?.trips_earning ?? 0,
      });
      expenseData.push(
        {
          label: "Salary Expense",
          value: item?.expense?.salary?.salary_expense ?? 0,
        },
        {
          label: "Machine Expense",
          value: item?.expense?.machine?.machine_expense ?? 0,
        },
        {
          label: "Fuel Expense",
          value: item?.expense?.fuel?.fuel_expense ?? 0,
        }
      );
      if (earningData.length > 0) {
        setEarningPieData(earningData);
      } else {
        setEarningPieData([{ label: "No Record Found", value: 100 }]);
      }

      if (expenseData.length > 0) {
        setExpensePieData(expenseData);
      } else {
        setExpensePieData([{ label: "No Record Found", value: 100 }]);
      }
    }
  }, [EarningExpenditureReportData]);

  const handleDateChange = (date: any) => {
    if (date !== null) {
      let start = date[0]?.setHours(0, 0, 0, 0);
      let end = date[1]?.setHours(23, 59, 59, 59);

      setMonthlyReportList({
        ...monthlyReportList,
        startDate: start ?? 0,
        endDate: end ?? 0,
      });
    } else {
      setMonthlyReportList({
        ...monthlyReportList,
        startDate: "",
        endDate: "",
      });
    }
  };

  const selectChange = (event: any) => {
    setMonthlyReportList({
      ...monthlyReportList,
      option: event.target.value as string,
    });
  };

  return renderContent({
    monthlyReportList,
    dateRange,
    handleDateChange,
    selectChange,
    EmpAttendanceReportData,
    refetchEmpAttendance,
    MachineReportData,
    refetchMachineReport,
    EarningExpenditureReportData,
    refetchEarningExpense,
    earningPieData,
    expensePieData,
    tripPiedata,
    fuelPiedata,
    sizing,
  });
};

export default memo(MonthlyReport);
