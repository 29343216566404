import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Checkbox,
  ListItemText,
  Slider,
} from "@mui/material";
import L from "leaflet";
import "leaflet-routing-machine";
import taxiImage from "../../assests/img/taxi.png";
import {
  useGetUserDetailQuery,
  useMachineInfoListQuery,
  useSitesListByOrgIdQuery,
} from "../../redux-services";
import {
  useGetVehiclesLatLonMutation,
  useGetVehiclesRegNoMutation,
} from "../../redux-services/api/VehicleMovementApi";
import { MapConfig } from "../../ constants";
import useLeafletMap from "../../components/LeafLetMap/useLeafletMap";
import { PageMeta } from "../../types/CommonTypes";

const createDotIcon = (color: string) => {
  return L.divIcon({
    className: "dot-icon",
    html: `<div style="background-color: ${color}; width: 8px; height: 8px; border-radius: 50%;"></div>`,
    iconSize: [19, 19],
  });
};

const createCustomIcon = (color: string) => {
  return L.divIcon({
    className: "custom-icon",
    html: `<div class="icon-border" style="border-color: ${color}; background-color: ${color};"></div>`,
    iconSize: [30, 30],
    iconAnchor: [15, 15],
  });
};

type LatLngWithTime = L.LatLng & { datetime?: string; speed?: number };

const TrackVehicleMovement = () => {
  const initialState = {
    selected_site: "",
    org_id: "",
    selectedRegNos: [] as string[],
  };

  const initialSiteState: PageMeta = {
    page: 1,
    per_page: Number(process.env.REACT_APP_PER_PAGE),
    searchText: "",
    order: process.env.REACT_APP_ORDER,
    order_by: "name",
  };

  const nameToIdMap = useRef<{ [key: string]: string }>({});
  const [formData, setFormData] = useState<any>(initialState);
  const [sitesList, setSitesData] = useState<PageMeta>(initialSiteState);
  const { data: userDetails } = useGetUserDetailQuery();
  const org_id: any = userDetails?.response?.organization_id;
  const [regNosList, setRegNosList] = useState([]);

  const orgId = localStorage.getItem("orgId");
  const [sites, setSites] = useState<any>([]);
  const { data } = useSitesListByOrgIdQuery({
    per_page: sitesList?.per_page ?? 20,
    page: 1,
    order: sitesList?.order,
    order_by: sitesList?.order_by,
    searchText: sitesList?.searchText ?? "",
    organization_id: orgId,
  });

  useEffect(() => {
    if (data !== undefined) {
      setSitesData(data?.response?.data);
      const updatedNameToIdMap: { [key: string]: string } = {};
      data?.response?.data?.forEach((item: any) => {
        updatedNameToIdMap[item.name as string] = item._id;
      });
      nameToIdMap.current = updatedNameToIdMap;
      setSites(Object.keys(updatedNameToIdMap));
    }
  }, [data]);

  const ID = nameToIdMap.current[formData.selected_site] || "";
  const { data: machineData } = useMachineInfoListQuery(
    {
      per_page: 100,
      page: 1,
      order: initialSiteState.order,
      order_by: initialSiteState.order_by,
      searchText: initialSiteState.searchText,
      id: ID,
    },
    {
      skip: !ID,
    }
  );

  const regNos = machineData?.response?.data?.map(
    (item: any) => item?.number_plate
  );
  useEffect(() => {
    setRegNosList(regNos);
  }, [regNos]);

  useEffect(() => {
    console.log(nameToIdMap.current, "nameToIdMap");
    if (nameToIdMap.current) {
      const values = Object.keys(nameToIdMap.current);
      setSites(values);
      console.log(sites, "nameToIdMap ");
    }
  }, [nameToIdMap.current, sites]);

  const formatDate = (date: any) => {
    const d = new Date(date);
    const month = `${d.getMonth() + 1}`.padStart(2, "0");
    const day = `${d.getDate()}`.padStart(2, "0");
    const year = d.getFullYear();
    return [year, month, day].join("-");
  };

  const currentDate = formatDate(new Date());
  const [fromDate, setFromDate] = useState<any>(currentDate);
  const [getVehicleRegNos] = useGetVehiclesRegNoMutation();
  const [getVehicleCoordinates] = useGetVehiclesLatLonMutation();
  const [playbackSpeed, setPlaybackSpeed] = useState<number>(1);
  const [playing, setPlaying] = useState<boolean>(false);
  const [markers, setMarkers] = useState<{ [key: string]: L.Marker }>({});
  const [polylines, setPolylines] = useState<{ [key: string]: L.Polyline }>({});
  const mapRef = useRef<HTMLDivElement>(null);
  const map = useLeafletMap(mapRef, MapConfig.tileLayer.uri);
  const coordinatesMap = useRef<{ [key: string]: LatLngWithTime[] }>({});
  const intervalRef = useRef<{ [key: string]: NodeJS.Timeout | undefined }>({});
  const [dots, setDots] = useState<{ [key: string]: L.Marker }>({});
  const [sliderValue, setSliderValue] = useState<number>(0);

  const colorMapping: { [key: string]: string } = {
    0: "yellow",
    1: "blue",
    2: "red",
    3: "pink",
    4: "brown",
    5: "white",
  };

  const clearDots = () => {
    Object.values(dots).forEach((dot) => dot.remove());
    setDots({});
  };

  useEffect(() => {
    if (org_id) {
      setFormData((prevState: any) => ({
        ...prevState,
        org_id,
      }));
    }
  }, [org_id]);

  useEffect(() => {
    if (org_id && formData.vehicle_type === "Tata Edge") {
      const req = {
        vehicle_type: 1,
        org_id,
      };
      getVehicleRegNos(req);
    }
  }, [org_id, formData.vehicle_type, getVehicleRegNos]);

  const selectSiteChange = (event: SelectChangeEvent) => {
    const selectedSite = event.target.value as string;
    if (!playing) {
      setFormData({
        ...formData,
        selected_site: selectedSite,
        selectedRegNos: [],
      });
      clearMarkersAndPolylines();
      clearAllLayers();
      clearDots();
    }
  };

  const updateBoundsDirectly = (coords: any) => {
    if (map) {
      const bounds = L.latLngBounds(
        coords.map((coord: any) => L.latLng(coord))
      );
      map.fitBounds(bounds);
    }
  };

  const processResponses = (responses: any[]) => {
    const newCoordinatesMap: { [key: string]: LatLngWithTime[] } = {};
    let allCoords: LatLngWithTime[] = [];
    let firstTime: string | null = null;
    let lastTime: string | null = null;

    responses.forEach((response, index) => {
      if ("data" in response && response.data.response) {
        const regNo = formData.selectedRegNos[index];
        const vehicleCoordinates = mapVehicleCoordinates(
          response.data.response,
          firstTime
        );

        // Filter out null values and invalid coordinates
        newCoordinatesMap[regNo] = vehicleCoordinates.filter(
          (coord) =>
            coord !== null &&
            typeof coord.lat === "number" &&
            typeof coord.lng === "number"
        ) as LatLngWithTime[];

        allCoords = [...allCoords, ...newCoordinatesMap[regNo]];
      }
    });

    return { newCoordinatesMap, allCoords, firstTime, lastTime };
  };

  const mapVehicleCoordinates = (coords: any[], firstTime: string | null) => {
    return coords
      .map((coord: any) => {
        if (coord[0] != null && coord[1] != null) {
          if (!firstTime) firstTime = coord[2];
          return {
            ...new L.LatLng(coord[0], coord[1]),
            datetime: coord[2],
            speed: coord[3],
          };
        } else {
          return null;
        }
      })
      .filter(Boolean);
  };

  const updateMap = (
    newCoordinatesMap: { [key: string]: LatLngWithTime[] },
    allCoords: LatLngWithTime[]
  ) => {
    if (map) {
      clearMarkersAndPolylines();
      clearDots();

      const newMarkers: { [key: string]: L.Marker } = {};
      const newPolylines: { [key: string]: L.Polyline } = {};

      Object.keys(newCoordinatesMap).forEach((regNo, index) => {
        const vehicleCoordinates = newCoordinatesMap[regNo];
        if (vehicleCoordinates?.length > 0) {
          const color = colorMapping[index % 4];
          const polyline = L.polyline([], { color }).addTo(map);
          newPolylines[regNo] = polyline;

          const marker = L.marker(vehicleCoordinates[0], {
            icon: L.icon({ iconUrl: taxiImage, iconSize: [30, 30] }),
          })
            .addTo(map)
            .bindTooltip(regNo, {
              permanent: true,
              direction: "left",
              opacity: 1.0,
            })
            .setOpacity(1.0);
          newMarkers[regNo] = marker;
        }
      });

      setMarkers(newMarkers);
      setPolylines(newPolylines);
      updateBoundsDirectly(allCoords);
    }
  };

  useEffect(() => {
    const req = { reported_at: "", reg_no: "" };

    const fetchVehicleCoordinates = async () => {
      if (formData.selectedRegNos.length > 0 && fromDate) {
        try {
          const regNoPromises = formData.selectedRegNos.map((regNo: string) => {
            req.reported_at = fromDate;
            req.reg_no = regNo;
            return getVehicleCoordinates(req);
          });

          const responses = await Promise.all(regNoPromises);
          const { newCoordinatesMap, allCoords, firstTime, lastTime } =
            processResponses(responses);

          coordinatesMap.current = newCoordinatesMap;
          updateMap(newCoordinatesMap, allCoords);

          if (firstTime && lastTime) {
            setSliderValue(0);
          }
        } catch (error) {
          console.error("Error fetching vehicle coordinates:", error);
        }
      }
    };

    fetchVehicleCoordinates();
  }, [formData.selectedRegNos, fromDate, getVehicleCoordinates, map]);

  const handleChange = (event: any) => {
    if (!playing) {
      const {
        target: { value },
      } = event;
      setFormData({
        ...formData,
        selectedRegNos: typeof value === "string" ? value.split(",") : value,
      });
    }
  };

  const onFromDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    clearDots();
    if (!playing) {
      setFromDate(value);
    }
  };

  const getMaxCoordinatesLength = () => {
    let maxLength = 0;
    Object.values(coordinatesMap.current).forEach((coords) => {
      if (coords.length > maxLength) {
        maxLength = coords.length;
      }
    });
    return maxLength;
  };

  const maxCoordinatesLength = getMaxCoordinatesLength();
  const [sliderMax, setSliderMax] = useState(maxCoordinatesLength);

  const clearMarkersAndPolylines = () => {
    Object.values(markers).forEach((marker) => map?.removeLayer(marker));
    Object.values(polylines).forEach((polyline) => map?.removeLayer(polyline));
    setMarkers({});
    setPolylines({});
  };

  const updateDotsState = (
    prevDots: Record<string, L.Marker>,
    regNo: string,
    currentIndex: number,
    dot: L.Marker
  ) => {
    return {
      ...prevDots,
      [`${regNo}-${currentIndex}`]: dot,
    };
  };

  const handlePlayClick = () => {
    if (!playing) {
      setPlaying(true);
      const vehiclesToComplete = new Set(Object.keys(markers));

      if (vehiclesToComplete.size > 0) {
        const maxLength = getMaxLength(vehiclesToComplete);
        if (maxLength > 0) {
          setSliderMax(maxLength - 1);
          setSliderValue(0);
        }

        vehiclesToComplete.forEach((regNo) =>
          startVehiclePlayback(regNo, vehiclesToComplete)
        );
      }
    } else {
      stopPlayback();
    }
  };

  const getMaxLength = (vehiclesToComplete: any) => {
    let maxLength = 0;
    vehiclesToComplete.forEach((regNo: any) => {
      const vehicleCoordinates = coordinatesMap.current[regNo];
      if (vehicleCoordinates?.length > 0) {
        maxLength = Math.max(maxLength, vehicleCoordinates.length);
      }
    });
    return maxLength;
  };

  const startVehiclePlayback = (regNo: any, vehiclesToComplete: any) => {
    const vehicleCoordinates = coordinatesMap.current[regNo];
    if (!vehicleCoordinates?.length) return;

    let currentIndex = sliderValue;
    const initialPosition = vehicleCoordinates[currentIndex];
    const startTime = vehicleCoordinates[0]?.datetime;
    const endTime = vehicleCoordinates[vehicleCoordinates.length - 1]?.datetime;

    const marker = markers[regNo];
    const polyline = polylines[regNo];
    const color = getVehicleColor(regNo);

    if (initialPosition) {
      initializeVehicleMarker(
        marker,
        polyline,
        vehicleCoordinates,
        currentIndex,
        color
      );
      intervalRef.current[regNo] = createIntervalForVehiclePlayback({
        regNo,
        marker,
        polyline,
        vehicleCoordinates,
        vehiclesToComplete,
        startTime,
        endTime,
        currentIndex,
      });
    }
  };

  const getVehicleColor = (regNo: any) => {
    return colorMapping[
      formData.selectedRegNos.indexOf(regNo) % Object.keys(colorMapping).length
    ];
  };

  const initializeVehicleMarker = (
    marker: any,
    polyline: any,
    vehicleCoordinates: any,
    currentIndex: any,
    color: any
  ) => {
    polyline.setLatLngs(vehicleCoordinates.slice(0, currentIndex + 1));
    if (map) {
      const customIcon = createCustomIcon(color);
      marker
        .setIcon(customIcon)
        .setLatLng(vehicleCoordinates[currentIndex])
        .addTo(map);
    }
  };

  const createIntervalForVehiclePlayback = ({
    regNo,
    marker,
    polyline,
    vehicleCoordinates,
    vehiclesToComplete,
    startTime,
    endTime,
    currentIndex,
  }: {
    regNo: string;
    marker: any;
    polyline: any;
    vehicleCoordinates: Array<any>;
    vehiclesToComplete: Array<any>;
    startTime: any;
    endTime: any;
    currentIndex: number;
  }) => {
    return setInterval(() => {
      if (currentIndex >= vehicleCoordinates.length) {
        finishVehiclePlayback(regNo, vehiclesToComplete);
        return;
      }
      const nextPosition = vehicleCoordinates[currentIndex];
      updateVehiclePosition(
        regNo,
        marker,
        polyline,
        nextPosition,
        startTime,
        endTime,
        currentIndex
      );
      currentIndex++; // Incrementing the index
    }, 1000 / playbackSpeed);
  };

  const updateVehiclePosition = (
    regNo: any,
    marker: any,
    polyline: any,
    nextPosition: any,
    startTime: any,
    endTime: any,
    currentIndex: any
  ) => {
    if (nextPosition?.lat != null && nextPosition?.lng != null) {
      const nextTime = nextPosition.datetime;
      const nextSpeed = nextPosition.speed;

      if (map) {
        marker.setLatLng(nextPosition).addTo(map);
        bindTooltipToMarker(
          marker,
          regNo,
          nextSpeed,
          startTime,
          endTime,
          nextTime
        );
      }

      polyline.addLatLng(nextPosition);
      addDotToMap(polyline, nextPosition, regNo, currentIndex);
      setSliderValue(currentIndex);
    }
  };

  const bindTooltipToMarker = (
    marker: any,
    regNo: any,
    speed: any,
    startTime: any,
    endTime: any,
    currentTime: any
  ) => {
    const tooltipContent = `
      Reg No: ${regNo}<br>
      Speed: ${speed} km/h<br>
      Start Time: ${startTime}<br>
      End Time: ${endTime}<br>
      Current Time: ${currentTime}
    `;
    marker
      .bindTooltip(tooltipContent, {
        permanent: true,
        direction: "right",
        opacity: 1.0,
      })
      .setOpacity(1.0);
  };

  const addDotToMap = (
    polyline: any,
    position: any,
    regNo: any,
    currentIndex: any
  ) => {
    const dotIcon = createDotIcon(polyline.options.color || "blue");
    if (map) {
      const dot = L.marker([position.lat, position.lng], {
        icon: dotIcon,
      }).addTo(map);
      setDots((prevDots) =>
        updateDotsState(prevDots, regNo, currentIndex, dot)
      );
    } else {
      console.error("Map is undefined, cannot add marker.");
    }
  };

  const finishVehiclePlayback = (regNo: any, vehiclesToComplete: any) => {
    clearInterval(intervalRef.current[regNo]);
    vehiclesToComplete.delete(regNo);
    if (vehiclesToComplete.size === 0) {
      setPlaying(false);
      console.log("All vehicles have completed plotting.");
    }
  };

  const stopPlayback = () => {
    setPlaying(false);
    Object.keys(intervalRef.current).forEach((regNo) =>
      clearInterval(intervalRef.current[regNo])
    );
  };

  const clearAllLayers = () => {
    if (map) {
      map.eachLayer((layer) => {
        if (
          layer instanceof L.Marker &&
          layer.options.icon &&
          layer.options.icon.options.className === "dot-icon"
        ) {
          map.removeLayer(layer);
        }
        if (
          layer instanceof L.Polyline &&
          layer.options &&
          layer.options.className === "vehicle-path"
        ) {
          map.removeLayer(layer);
        }
      });
    }
  };

  const handleSliderChange = (newValue: number) => {
    setSliderValue(newValue);

    Object.keys(markers).forEach((regNo) => {
      const vehicleCoordinates = coordinatesMap.current[regNo];
      if (vehicleCoordinates && vehicleCoordinates.length > 0) {
        const newPosition = vehicleCoordinates[newValue];
        const startTime = vehicleCoordinates[0].datetime;
        const endTime =
          vehicleCoordinates[vehicleCoordinates.length - 1].datetime;

        if (newPosition && newPosition.lat != null && newPosition.lng != null) {
          const newTime = newPosition.datetime;
          const newSpeed = newPosition.speed;
          const marker = markers[regNo];
          const polyline = polylines[regNo];

          if (map) {
            marker.setLatLng(newPosition).addTo(map);

            const tooltipContent = `
              Reg No: ${regNo}<br>
              Speed: ${newSpeed} km/h<br>
              Start Time: ${startTime}<br>
              End Time: ${endTime}<br>
              Current Time: ${newTime}
            `;
            marker
              .bindTooltip(tooltipContent, {
                permanent: true,
                direction: "right",
                opacity: 0.8,
              })
              .setOpacity(1.0);

            polyline.setLatLngs(vehicleCoordinates.slice(0, newValue + 1));

            clearAllLayers();
            vehicleCoordinates.slice(0, newValue + 1).forEach((coord) => {
              const dotIcon = createDotIcon(polyline.options.color ?? "blue");
              L.marker([coord.lat, coord.lng], { icon: dotIcon }).addTo(map);
            });
          }
        }
      }
    });
  };

  const handlePlaybackSpeedChange = (event: SelectChangeEvent<number>) => {
    if (!playing) {
      setPlaybackSpeed(event.target.value as number);
    } else {
      clearMarkersAndPolylines();
      clearAllLayers();
      clearDots();
    }
  };

  return (
    <div>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={3}>
            <FormControl fullWidth>
              <InputLabel>Select Site</InputLabel>
              <Select
                value={formData.selectedSite}
                labelId="vehicle-type-label"
                id="vehicle-type-select"
                label="Vehicle Type"
                disabled={playing}
                onChange={selectSiteChange}
              >
                {nameToIdMap.current &&
                  sites?.map((site: string, index: number) => (
                    <MenuItem key={index} value={site}>
                      {site}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={3}>
            <FormControl fullWidth>
              <TextField
                label="For Date"
                type="date"
                value={fromDate}
                disabled={playing}
                onChange={onFromDateChange}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={3}>
            <FormControl fullWidth>
              <InputLabel className="Registrationber">
                Registration Numbers
              </InputLabel>
              <Select
                multiple
                value={formData.selectedRegNos}
                onChange={handleChange}
                disabled={playing}
                renderValue={(selected) => selected.join(", ")}
              >
                {regNosList?.map((reg_no: any) => (
                  <MenuItem key={reg_no} value={reg_no}>
                    <Checkbox
                      checked={formData.selectedRegNos.indexOf(reg_no) > -1}
                    />
                    <ListItemText primary={reg_no} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={3}>
            <Box className="Playbox-flex">
              <FormControl fullWidth>
                <InputLabel className="Playback-Speed-label">
                  Playback Speed
                </InputLabel>
                <Select
                  value={playbackSpeed}
                  disabled={playing}
                  onChange={handlePlaybackSpeedChange}
                >
                  <MenuItem value={1}>1x</MenuItem>
                  <MenuItem value={2}>2x</MenuItem>
                  <MenuItem value={5}>5x</MenuItem>
                  <MenuItem value={10}>10x</MenuItem>
                </Select>
              </FormControl>
              <Button
                variant="contained"
                color="primary"
                className="secondary-btn"
                onClick={handlePlayClick}
              >
                {playing ? "Stop" : "Play"}
              </Button>
            </Box>
          </Grid>
        </Grid>

        <Box ref={mapRef} mt={2} style={{ height: "80vh" }} />
        <Box mt={2}>
          <Slider
            value={sliderValue}
            onChange={(e, newValue) => {
              if (Array.isArray(newValue)) {
                handleSliderChange(newValue[0]);
              } else {
                handleSliderChange(newValue);
              }
            }}
            min={0}
            max={sliderMax}
            valueLabelDisplay="auto"
          />
        </Box>
      </Box>
    </div>
  );
};

export default TrackVehicleMovement;
