import React, { useMemo, useCallback, useState } from 'react'
import {
    Button,
    Paper,
    StyledEngineProvider,
    Table,
    TableBody,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    Toolbar,
    Tooltip,
    Typography,
    debounce,
} from "@mui/material";
import { TableBodyElement } from '../../components/TableBody/TableBodyElement';
import { TableHeadElement } from '../../components/TableBody/TableHeadElement';
import { ColumnsType, Order, PageMeta, SortingData } from '../../types/CommonTypes';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import ConfirmBox from '../../components/ConfirmBox/ConfirmBox';
import { SearchElement } from '../../components/Search/Search';
import { useDeleteMachineInfoMutation, useMachineInfoListQuery, useUpdateMachineStatusMutation } from '../../redux-services';
import AddMachineType from './MachineType'
import { MachineDocumentTypeApi } from '../../redux-services/api/MachineDocumentTypeApi';
import AddMachineDocumentType from './MachineDocumentType';
import arrowImage from "../../assests/img/arrow.svg"
interface MachineType {
    _id: string;
    status: boolean;
}
const tableColumns: ColumnsType[] = [
    {
        id: "id",
        sort: false,
        label: "S.No.",
    },
    {
        id: "machine_type",
        sort: true,
        label: "Machine Type",
    },
    {
        id: "site_name",
        sort: true,
        label: "Site Name",
    },
    {
        id: "company",
        sort: true,
        label: "Company",
    },
    {
        id: "machine_model",
        sort: true,
        label: "Model"
    },
    {
        id: "number_plate",
        sort: true,
        label: "Number Plate"
    },
    {
        id: "engine_run_hour",
        sort: true,
        label: "Engine Run Hour"
    },
    {
        id: "is_rented",
        sort: true,
        label: "Rented Machine"
    },
    {
        id: "action",
        sort: false,
        label: "Action",
        style: { width: "18%" },
    },
];

let initialOrder: string = process.env.REACT_APP_ORDER as string;

const MachineInfo = () => {
    const initialState: PageMeta = {
        page: 1,
        per_page: Number(process.env.REACT_APP_PER_PAGE),
        searchText: "",
        order: process.env.REACT_APP_ORDER,
        order_by: process.env.REACT_APP_ORDER_BY,
    };
    let navigate = useNavigate();
    let query = useLocation();
    const [page, setPage] = useState<number>(1);
    const [orderBy, setOrderBy] = useState<keyof SortingData>(
        process.env.REACT_APP_ORDER_BY as keyof SortingData
    );
    const [open, setOpen] = useState<boolean>(false);
    const [documentOpen, setDocumentOpen] = useState<boolean>(false);
    const [order, setOrder] = useState<Order>(initialOrder as Order);
    const [isConfirmationOpen, setIsConfirmationOpen] = useState<boolean>(false);
    const [activeMachineId, setActiveMachineId] = useState<string>("");
    const [machineStatus, setMachineStatus] = useState<boolean>(false);

    const [search, setSearch] = useState<string>("");
    const [rowsPerPage, setRowsPerPage] = useState<number>(
        initialState.per_page as number
    );
    const [machineInfoList, setMachineInfoList] = useState<PageMeta>(initialState);
    const [isClose, setIsClose] = useState<boolean>(false);
    const [Id, setId] = useState<string>("");

    const {  data } = useMachineInfoListQuery({
        per_page: machineInfoList.per_page ?? rowsPerPage,
        page: query.state ?? machineInfoList.page,
        order: machineInfoList.order ?? order,
        order_by: machineInfoList.order_by ?? orderBy,
        searchText: machineInfoList.searchText ?? search,
    });
    const [deleteMachineInfo] = useDeleteMachineInfoMutation();
    const [updateActiveStatus] = useUpdateMachineStatusMutation();

    const handleRequestSort = (
        event: React.MouseEvent<unknown>,
        property: keyof SortingData
    ) => {
        const isAsc = orderBy === property && order === "asc";
        const newOrder = isAsc ? "desc" : "asc";
        setOrder(newOrder);
        setOrderBy(property);
        setMachineInfoList({
            ...machineInfoList,
            order: newOrder,
            order_by: property,
        });
    };

    const createSortHandler = (
        event: React.MouseEvent<unknown>,
        property: keyof SortingData
    ) => {
        if (property !== ("action" as keyof SortingData)) {
            handleRequestSort(event, property);
        }
    };

    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number
    ) => {
        setPage(newPage + 1);
        setMachineInfoList({ ...machineInfoList, page: newPage + 1 });

        if (query.state !== null) {
            navigate(query.pathname, {});
        }
    };
    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setMachineInfoList({
            ...machineInfoList,
            per_page: parseInt(event.target.value),
            page: 1,
        });
        setPage(1);
    };

    const searchHandler = useMemo(() => {
        return debounce((event: React.ChangeEvent<HTMLInputElement>) => {
            setSearch(event.target.value);
            setMachineInfoList({
                page: 1,
                per_page: machineInfoList.per_page,
                order: initialOrder,
                searchText: event.target.value,
            });
        }, 800);
    }, []);

    const confirmBoxClose = (res: boolean) => {
        setIsClose(res);
        if (res) {
            setIsClose(false);
            deleteMachineInfo(Id);
        } else {
            setIsClose(res);
        }
    };
    const handleToggleActive = (id: string) => {
        const machine = data?.response.data.find((machine: MachineType) => machine._id === id);
        if (machine) {
            setActiveMachineId(id);
            setIsConfirmationOpen(true);
            setMachineStatus(machine.is_active);
        }
    };
    const handleConfirmationResponse = (confirmed: boolean) => {
        if (confirmed) {
            const newStatus = !machineStatus;
            updateActiveStatus({ id: activeMachineId, status: newStatus });
        }
        setActiveMachineId("");
        setIsConfirmationOpen(false);
    };

    const handleModel = useCallback((isOpen: boolean) => {
        setOpen(isOpen);
    }, []);

    const handleDocumentModel = useCallback((isOpen: boolean) => {
        setDocumentOpen(isOpen);
    }, []);
    const breadcrumbs = [
        { name: 'Dashboard', to: '/admin/dashboard' },
        { name: 'Machinery Lists', to: '/admin/machine' },
    ];


    return (
        <div>
            <Paper className="table-outers mb-2">
                <Toolbar className="machinepagehead">
                    <div className="breadcrumbs" style={{ display: 'flex', alignItems: 'center' }}>
                        {breadcrumbs.map((crumb, index) => (
                            <React.Fragment key={index}>
                                {index > 0 && <img src={arrowImage} alt="arrow" style={{ margin: '0 8px', verticalAlign: 'middle' }} />}
                                {index === breadcrumbs.length - 1 ? (
                                    <Typography variant="body2" component="span" style={{ color: 'white' }}>{crumb.name}</Typography>
                                ) : (
                                    <Link to={crumb.to} className="breadcrumb-link" style={{ color: 'white' }}>{crumb.name}</Link>
                                )}
                            </React.Fragment>
                        ))}
                    </div>
                </Toolbar>
                <Toolbar>
                    <Typography
                        sx={{ flex: '1 1 20%' }}
                        variant="h5"
                        id="tableTitle"
                        component="div"
                        className="inner-headings"
                    >
                        Machinery Lists
                    </Typography>
                    <div className="flex-boxinner">
                        <Typography
                            sx={{ flex: '1 1 auto' }}
                        >
                            <SearchElement
                                searchFn={searchHandler}
                                searchTag={"Search Machinery..."}
                            />

                        </Typography>
                        <Tooltip title="Add Machine Info" arrow className="me-1 mt-1">
                            <Button className="secondary-btn" onClick={() => navigate('/admin/machine/add')} type="submit" variant="contained" color="primary">Add Machinery</Button>
                        </Tooltip>
                        <Tooltip title="View Machine Report" arrow className="me-1 mt-1">
                            <Button className="secondary-btn" onClick={() => {
                                const currentUrl = query.pathname + query.search;
                                navigate('/admin/machine/report', { state: { from: currentUrl } });
                            }} type="submit" variant="contained" color="primary">Machine Live Report</Button>
                        </Tooltip>
                        <Tooltip title="Add Machine Info" arrow className="me-1 mt-1">
                            <Button className="secondary-btn" onClick={() => setOpen(true)} type="submit" variant="contained" color="primary">Add Machine Type</Button>
                        </Tooltip>
                        <Tooltip title="Add Machine Document" arrow className="me-1 mt-1">
                            <Button className="secondary-btn" onClick={() => setDocumentOpen(true)} type="submit" variant="contained" color="primary">Add Document Type</Button>
                        </Tooltip>
                    </div>
                </Toolbar>
                <StyledEngineProvider>
                    <TableContainer component={Paper} className="table-outers">
                        <Table
                            sx={{ minWidth: 650 }}
                            size="small" aria-label="a dense table"
                            className="table-outers"
                        >
                            <TableHead>
                                <TableRow>
                                    <TableHeadElement
                                        setHead={tableColumns ? tableColumns : []}
                                        order={order}
                                        orderBy={orderBy}
                                        sortFn={(event, id: string) =>
                                            createSortHandler(event, id as keyof SortingData)
                                        }
                                    />
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableBodyElement
                                    selectedColumns={[
                                        "machine_type",
                                        "site_name",
                                        "company",
                                        "machine_model",
                                        "number_plate",
                                        "engine_run_hour",
                                        "is_rented"
                                    ]}
                                    setData={data?.response.data ? data?.response.data : []}
                                    editFn={(id) =>
                                        navigate(`/admin/machine/edit/${id}`, {
                                            state: page,
                                        })
                                    }
                                    deleteFn={(id) => {
                                        setIsClose(true);
                                        setId(id);
                                    }}
                                    viewMachineFn={(id: string, siteId: string) =>
                                        navigate(`/admin/site/${siteId}/machine/${id}`, {
                                            state: page,
                                        })
                                    }
                                    viewMachineHistoryFn={(id: string) => {
                                        const currentUrl = query.pathname + query.search;
                                        navigate(`/admin/site/machine/${id}/history`, { state: { from: currentUrl, page: page } });
                                    }}
                                    viewServiceHistoryFunction={(id: string) => {
                                        const currentUrl = query.pathname + query.search;
                                        navigate(`/admin/site/machine/${id}/servicehistory`, { state: { from: currentUrl, page: page } });
                                    }}
                                    isActivefn={(id) => handleToggleActive(id)}
                                    pageData={{
                                        limit:
                                            rowsPerPage ?? Number(process.env.REACT_APP_PER_PAGE),
                                        page: data?.response.page as number,
                                    }}
                                />
                            </TableBody>
                        </Table>
                        <TablePagination
                            component="div"
                            count={data?.response.total ?? 0}
                            page={data?.response.page ?? 0}
                            rowsPerPageOptions={[20, 50, 100]}
                            onPageChange={handleChangePage}
                            rowsPerPage={
                                rowsPerPage ?? Number(process.env.REACT_APP_PER_PAGE)
                            }
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </TableContainer>
                </StyledEngineProvider>
            </Paper>
            <ConfirmBox
                title={`Machine ${machineStatus ? 'Deactivate' : 'Activate'} Confirmation`}
                message={activeMachineId ? `Are you sure you want to ${machineStatus ? 'deactivate' : 'activate'} this machine?` : ''}
                open={isConfirmationOpen}
                onClose={handleConfirmationResponse}
            />
            <ConfirmBox
                title="Machinery Delete Confirmation"
                message="Are you sure, you want to delete this Machinery?"
                open={isClose}
                onClose={confirmBoxClose}
            />
            {open &&
                <AddMachineType
                    open={open}
                    handleModel={handleModel}
          
                />

            }
            {documentOpen &&
                <AddMachineDocumentType
                    documentOpen={documentOpen}
                    handleDocumentModel={handleDocumentModel}

                />

            }

        </div>
    )
}

export default MachineInfo;