import React, { useEffect, useState } from 'react';
import { Alert, Box, Button, Container, TextField, Typography } from "@mui/material";
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { setError, useGetEmailVerificationQuery, useResetPasswordMutation } from "../redux-services";
import { useDispatch } from "react-redux";
import Error from "../components/ErrorBox/Error";
import { useParams } from 'react-router-dom';
import { Buffer } from 'buffer';

export interface LoginType {
    email: string;
    password: string;
    confirm_password: string;
}

const ResetPassword = () => {
    const { email } = useParams();
    const decodedEmail = Buffer.from(email ?? '', 'base64').toString('utf-8');
    const initialState = {
        email: email ?? '',
        password: '',
        confirm_password: '',
    };

    const { isSuccess, data: checkEmailStatus } = useGetEmailVerificationQuery(email, { skip: !email });

    const [resetPassword, result] = useResetPasswordMutation();
    const dispatch = useDispatch();
    const [passwordError, setPasswordError] = useState('');
    const [snackbarOpen, setSnackbarOpen] = useState(false);

    const [formData, setFormData] = useState<LoginType>(initialState);
    const [showMsg, setShowMsg] = useState<Boolean>(false);
    const [showError, setShowError] = useState({ password: false, confirm_password: false });
    const [isShowPwd, setIsShowPwd] = useState<Boolean>(false);
    const [isShowConfirmPwd, setIsShowConfirmPwd] = useState<Boolean>(false);
    const [isVerified, setIsVerified] = useState<Boolean>(false);

    useEffect(() => {
        if (isSuccess && checkEmailStatus?.status === 200 && checkEmailStatus?.response) {
            setIsVerified(true);
        }
    }, [isSuccess, checkEmailStatus]);

    useEffect(() => {
        if (result?.isSuccess && result?.data?.status === 200) {
            setShowMsg(true);
        }
    }, [result]);

    const handleOnChange = (e: React.ChangeEvent<HTMLInputElement> | any) => {
        e.preventDefault();
        const { name, value } = e.target;
      
        setFormData({ ...formData, [name]: value });
      
        if (name === 'password') {
          if (!validatePassword(value)) {
            setPasswordError('Password should be 6 characters long, include a lowercase letter, an uppercase letter, a number, and a special character.'); 
            setShowError(prev => ({ ...prev, password: true }));
          } else {
            setPasswordError(''); 
            setShowError(prev => ({ ...prev, password: false }));
          }
        } else if (name === 'confirm_password') {
          if (value !== formData.password) {
            setPasswordError('The confirm password confirmation does not match the password provided.');
            setShowError(prev => ({ ...prev, confirm_password: true }));
          } else {
            setPasswordError('');
            setShowError(prev => ({ ...prev, confirm_password: false }));
          }
        }
      };
    const validatePassword = (password: string) => {
        const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*?&#]{6,}$/;
        
        return passwordRegex.test(password);
      };
    const handleSubmit = (event: any) => {
        event.preventDefault();
        if (formData.password !== formData.confirm_password) {
            setShowError(prev => ({ ...prev, confirm_password: true }));
            setPasswordError('The confirm password confirmation does not match the password provided.');
        } else if (!validatePassword(formData.password)) {
            setShowError(prev => ({ ...prev, password: true }));
            setPasswordError('Password should be 6 characters long and must include at least one special symbol, one number, one uppercase letter, and one lowercase letter.');
        } else {
            setShowError({ password: false, confirm_password: false });
            dispatch(setError([]));
            resetPassword(formData);
            setSnackbarOpen(true);
        }
    };

    return (
        <>
            {(isSuccess && isVerified) ? (
                <Box className="reset-container">
                    <Container component="main" style={{ width: "100%", textAlign: "center", justifyContent: "center" }}>
                        <h2 style={{ marginTop: "20%" }}>
                            Your Account is already Verified. Please <a href='/login' style={{ color: "#fff" }}>Click here</a> to login.
                        </h2>
                    </Container>
                </Box>
            ) : (
                (result?.data?.status === 200 && !isVerified) ? (
                    <Box className="reset-container">
                        <Container component="main" style={{ width: "100%", textAlign: "center", justifyContent: "center" }}>
                            <h2 style={{ marginTop: "20%" }}>
                                Your password is set successfully. Please <a href='/login' style={{ color: "#fff" }}>Click here</a> to login.
                            </h2>
                        </Container>
                    </Box>
                ) : (
                    <Box className="login-container">
                        <Container component="main" maxWidth="sm">
                            <Box className="text-center">
                                <img
                                    src="/img/logo.png"
                                    alt="white_logo"
                                    className="web-logo"
                                />
                            </Box>
                            {showMsg && result?.isSuccess &&
                                <Box>
                                    <Alert variant="outlined" severity="success" onClose={() => { setShowMsg(false) }}>
                                        {result?.data?.message} Please <a href='/login' style={{ color: "#fff" }}>Click here</a> to login.
                                    </Alert>
                                </Box>
                            }
                            <Box className="loginBox mt-3"
                                sx={{
                                    boxShadow: 3,
                                    borderRadius: 2,
                                    px: 4,
                                    py: 6,
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",

                                }}
                            >
                                <Typography className="inner-headings" component="h1" variant="h5">
                                    Reset Password
                                </Typography>
                                <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
                                    <TextField
                                        margin="normal"
                                        fullWidth
                                        name="Email"
                                        label="Email Address"
                                        type='text'
                                        id="Email"
                                        autoComplete="Email"
                                        value={decodedEmail}
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                    />
                                    <span className="password-login">
                                        <TextField
                                            margin="normal"
                                            required
                                            fullWidth
                                            name="password"
                                            label="Password"
                                            type={!isShowPwd ? "password" : 'text'}
                                            id="password"
                                            autoComplete="password"
                                            value={formData.password}
                                            onChange={handleOnChange}
                                            error={showError.password}
                                            helperText={showError.password && passwordError}
                                        />
                                        <Box onClick={() => { setIsShowPwd(!isShowPwd) }} className="pwd-icon">
                                            {isShowPwd ? <VisibilityOffIcon /> : <VisibilityIcon />}
                                        </Box>
                                        <Error current_key="password" />
                                    </span>
                                    <span className="password-login">
                                        <TextField
                                            margin="normal"
                                            required
                                            fullWidth
                                            name="confirm_password"
                                            label="Confirm Password"
                                            type={!isShowConfirmPwd ? "password" : 'text'}
                                            id="confirm_password"
                                            autoComplete="confirm_password"
                                            value={formData.confirm_password}
                                            onChange={handleOnChange}
                                            error={showError.confirm_password}
                                            helperText={showError.confirm_password && passwordError}
                                        />
                                        <Box onClick={() => { setIsShowConfirmPwd(!isShowConfirmPwd) }} className="pwd-icon">
                                            {isShowConfirmPwd ? <VisibilityOffIcon /> : <VisibilityIcon />}
                                        </Box>
                                        {/* {showError &&
                                            <Typography variant="body1" className="error-alert">
                                                The confirm password confirmation does not match the password provided.
                                            </Typography>
                                        } */}
                                        <Error current_key="confirm_password" />
                                    </span>
                                    <Button
                                        type="submit"
                                        fullWidth
                                        className="secondary-btn py-3"
                                        variant="contained"
                                        sx={{ mt: 3, mb: 2 }}
                                    >
                                        Reset Password
                                    </Button>
                                </Box>
                            </Box>
                        </Container>
                    </Box>
                )
            )}
        </>
    )
}
export default ResetPassword;
