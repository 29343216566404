import { ChangeEvent, FC, SyntheticEvent, memo, useEffect, useState } from "react";
import { Autocomplete, Box, Button, Divider, Fade, Grid, Modal, TextField, Typography, Paper } from "@mui/material";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker'
import { LocalizationProvider } from '@mui/x-date-pickers';
import Backdrop from '@mui/material/Backdrop';
import Error from "../../../../components/ErrorBox/Error";
import { setError, useCreateFuelStorageMutation, useEditFuelStorageMutation, useGetFuelStorageByIdQuery, useGetFuelTypeListbyOrgIdQuery, useGetSitesByIdQuery } from "../../../../redux-services";
import { useDispatch, useSelector } from "react-redux";
import { FuelType } from "../../../../ constants";
import dayjs from "dayjs";
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { RootState } from "../../../../store/store";
const style = {
    position: 'absolute' as 'absolute',
    top: '20%',
    left: '50%',
    transform: 'translate(-50%, -20%)',
    width: 700,
    bgcolor: 'background.paper',
    border: '1px solid #000',
    boxShadow: 24,
    borderRadius: 2,
    p: 2,
};
export interface FormFields {
    fuel_type: string;
    quantity: any;
    site_id?: string;
    selected_fuel_type?: SelectedFuelType,
    storageId?: string;
    fuelReceipt_img: any,
    fuelReceipt_img_url: any;

    date_time: string | Record<string, any>;
    price_per_unit?: any;
}
export interface SelectedFuelType {
    fuel_type: string;
    company_name: string;
    _id: string;
}
const AddEditFuelStorage: FC<{ open: boolean, handleModel: any, siteId?: string, storageId?: string }> = ({ open, handleModel, siteId, storageId }) => {
    let dispatch = useDispatch();
    const intialState: FormFields = {
        fuel_type: '',
        quantity: '',
        site_id: siteId,
        storageId: "",
        date_time: "",

        fuelReceipt_img: null,
        fuelReceipt_img_url: "",
        price_per_unit: "",
        selected_fuel_type: { fuel_type: '', company_name: '', _id: '', }
    }
    const { data: SitesData } = useGetSitesByIdQuery(siteId, {
        skip: siteId ? false : true,
    });
    const userDetail = useSelector((state: RootState) => state.auth.user);

    const organizationName = SitesData?.response.organization_id?.[0]?.organization_name || '';
    const {  data: FuelTypeList } = useGetFuelTypeListbyOrgIdQuery(userDetail?.organization_id, {
        skip: !userDetail?.organization_id
    });

    const [submitted, setSubmitted] = useState(false);

    const [formData, setFormData] = useState<FormFields>(intialState);
    const [selectFuelType, setSelectFuelType] = useState(1);

    const [fuelTypeListData, setFuelTypeListData] = useState([]);

    const { data: getStorageDataById } = useGetFuelStorageByIdQuery(storageId, {
        skip: !storageId,
    });


    useEffect(() => {
        if (FuelTypeList?.status === 200) {
            let data = FuelTypeList?.response?.fuelTypeList;
            setFuelTypeListData(data);
        }
    }, [FuelTypeList]);

    const [createFuelStorage, createResult] = useCreateFuelStorageMutation();
    const [editFuelStorage, editResult] = useEditFuelStorageMutation();
    const handleDateTimeChange = (fieldName: string, value: any) => {

        setFormData(prevState => ({
            ...prevState,
            [fieldName]: new Date(value).valueOf()
        }));
    };

    const handleFuelType = (data: SelectedFuelType | any) => {
        setFormData({ ...formData, fuel_type: data?._id, selected_fuel_type: data })
        setSelectFuelType(data?.fuel_type);
    }

    useEffect(() => {
        return () => {
            setFormData(intialState)
        };
    }, []);

    useEffect(() => {
        if (getStorageDataById?.status === 200) {
            let fuelType = FuelTypeList?.response?.fuelTypeList?.find((item: SelectedFuelType) => item?._id === getStorageDataById?.response.fuel_type);
            setSelectFuelType(fuelType?.fuel_type);
            const unixTimestamp1 = getStorageDataById?.response.date_time;
            const dateObject1 = dayjs(new Date(unixTimestamp1));
            setFormData({
                ...formData,
                fuel_type: getStorageDataById?.response.fuel_type,
                quantity: getStorageDataById?.response.quantity,
                date_time: dateObject1,
                fuelReceipt_img: getStorageDataById?.response.fuelReceipt_img?.path,
                fuelReceipt_img_url: getStorageDataById?.response.fuelReceipt_img_url,
                selected_fuel_type: FuelTypeList?.response?.fuelTypeList?.length > 0 ? FuelTypeList?.response?.fuelTypeList?.find((item: SelectedFuelType) => item._id === getStorageDataById?.response.fuel_type)
                    : { fuel_type: '', company_name: '', _id: '' },
                storageId: storageId
            })
        }
    }, [getStorageDataById, FuelTypeList])


    const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement | any>) => {
        const { name, value } = e.target;
        if (Number(value) > 0) {
            if (storageId) {
                if (name === 'quantity' && getStorageDataById?.response?.quantity >= value) {
                    setFormData({ ...formData, [name]: (value) });
                }
            } else {
                setFormData({ ...formData, [name]: (value) });
            }
        }
    }

    const handleChangeImage = (event: any) => {
        let file = event.target.files[0];
        let setFileUrl = (URL.createObjectURL(file));
        setFormData({
            ...formData,
            fuelReceipt_img: file,
            fuelReceipt_img_url: setFileUrl,
        });
        const thumbnailElement = document.getElementById('images') as HTMLInputElement | null;

        if (thumbnailElement) {
            thumbnailElement.value = '';
        }
    }


    const handleSubmit = (e: SyntheticEvent<HTMLFormElement, SubmitEvent>) => {
        e.preventDefault();
        dispatch(setError([]));
        setSubmitted(true);
        let datee_time: any;
        if (typeof formData.date_time === 'object') {
            datee_time = formData.date_time.valueOf();

        } else {
            datee_time = formData.date_time;
        }
        let data = new FormData();
        data.append("fuelReceipt_img", formData.fuelReceipt_img as any);
        data.append("fuel_type", formData.fuel_type);
        data.append("date_time", datee_time.toString())
        data.append("quantity", formData.quantity.toString());
        data.append("storageId", formData.storageId ?? "");
        data.append("site_id", formData.site_id as string);

        data.append("price_per_unit", (formData.price_per_unit ?? 0).toString());
        data.append('storageId', storageId ? storageId : null as any);
        data.append("organization_name", organizationName);
        storageId ? editFuelStorage(data as any) : createFuelStorage(data as any);
    }
    if ((editResult.isSuccess && editResult.data.status === 200) || (createResult.isSuccess && createResult.data.status === 200)) {
        handleModel(false)
    }

    return (
        <>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
            >
                <Fade in={open}>
                    <Box sx={style} className='form-outers karuModal'>
                        <Typography id="transition-modal-title" variant='h5' className='inner-headings' component="h2">
                            {storageId ? "Edit" : "Add"} Fuel Storage
                        </Typography>
                        <HighlightOffIcon className="closeicon" onClick={() => handleModel(false)} />
                        <Box className="modalBody">
                            <Divider sx={{ marginTop: 2, marginBottom: 3 }} />
                            <form onSubmit={handleSubmit} >
                                <Grid container spacing={3}>
                                    <Grid item xs={6}>
                                        <Autocomplete
                                            id="select-fuel"
                                            options={fuelTypeListData ?? []}
                                            size='small'
                                            onChange={(event, newValue: SelectedFuelType) => {
                                                handleFuelType(newValue)
                                            }}
                                            PaperComponent={({ children }) => (
                                                <Paper style={{ background: "#0a243d" }}>{children}</Paper>
                                            )}
                                            value={formData.selected_fuel_type}
                                            autoHighlight
                                            getOptionLabel={(option: any) => FuelType[option?.fuel_type] === undefined ? "" :
                                                FuelType[option?.fuel_type] + ' (' + option?.company_name + ')'
                                            }
                                            renderOption={(props, option: any) => (
                                                <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                                    {FuelType[option?.fuel_type] === undefined ? "" :
                                                        FuelType[option?.fuel_type] + ' (' + option?.company_name + ')'
                                                    }
                                                </Box>
                                            )}
                                            disabled={storageId ? true : false}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label="Choose A Fuel Type"
                                                    inputProps={{
                                                        ...params.inputProps,
                                                        autoComplete: 'new-password', // disable autocomplete and autofill
                                                    }}
                                                    required
                                                />
                                            )}
                                        />

                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            required
                                            fullWidth
                                            id="quantity"
                                            label={`Fuel Quantity ${selectFuelType === 1 ? '(Ltr)' : '(Kg)'}`}
                                            name="quantity"
                                            type="number"
                                            size='small'
                                            inputProps={{
                                                inputMode: 'decimal', // Mobile browsers show numeric keyboard with decimal
                                                pattern: '[0-9]*[.,]?[0-9]*' // Pattern to allow decimal numbers
                                            }}
                                            autoComplete="off"
                                            value={formData.quantity}
                                            onChange={handleChange}
                                        />
                                        <Error current_key="quantity" />
                                    </Grid>
                                    {!storageId && <Grid item xs={6}>
                                        <TextField
                                            required
                                            fullWidth
                                            id="price_per_unit"
                                            label={"Price Per Unit" + ' (' + "₹" + ")"}
                                            name="price_per_unit"
                                            type="number"
                                            size='small'
                                            inputProps={{
                                                inputMode: 'decimal', 
                                                pattern: '[0-9]*[.,]?[0-9]*' 
                                            }}
                                            autoComplete="off"
                                            value={formData.price_per_unit}
                                            onChange={handleChange}
                                        />
                                        <Error current_key="price_per_unit" />
                                    </Grid>}
                                    <LocalizationProvider dateAdapter={AdapterDayjs} >

                                        <Grid item xs={12} md={6} className='custom-datepicker' sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', height: '100%' }}>
                                            <DateTimePicker
                                                label="Date Time"
                                                name="date_time"
                                                maxDate={dayjs()}
                                                className="mydatepicker"
                                                slotProps={{
                                                    textField: {
                                                        error: !formData.date_time,
                                                        helperText: submitted && !formData.date_time ? 'date field is required' : '',
                                                    },
                                                }}

                                                value={formData.date_time}
                                                onChange={(newValue) => handleDateTimeChange('date_time', newValue)}
                                                sx={{ width: '100%' }}

                                            />

                                        </Grid>

                                    </LocalizationProvider>
                                    <Grid item xs={12} md={6}>
                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                id="images"
                                                name="images"
                                                label="Receipt Images"
                                                className='accessory_img'
                                                type="file"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                size='small'
                                                inputProps={{ accept: 'image/*,.pdf,.doc,.docx,.application/msword,.application/vnd.openxmlformats-officedocument.wordprocessingml.document' }}
                                                autoComplete="images"
                                                onChange={handleChangeImage}
                                            />
                                            <Error current_key="images" />
                                        </Grid>
                                        {formData.fuelReceipt_img_url !== '' &&
                                            <Grid item xs={12} className='imageDiv'>
                                                <div >
                                                    <img src={formData.fuelReceipt_img_url} alt={`receipt-img`} className='preview-img' />
                                                </div>
                                            </Grid>
                                        }
                                    </Grid>


                                    <Grid item xs={12}>
                                        <Divider />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Button
                                            type="submit"
                                            variant="contained"
                                            color="primary"
                                            className="save-btn"
                                            style={{ marginRight: '10px' }}

                                        >
                                            Submit
                                        </Button>
                                        <Button
                                            variant="contained"
                                            color="secondary"
                                            className="close-btn"
                                            onClick={() => handleModel(false)}
                                        >
                                            Cancel
                                        </Button>
                                    </Grid>
                                </Grid>
                            </form>
                        </Box>
                    </Box>
                </Fade>
            </Modal>
        </>
    )
}

export default memo(AddEditFuelStorage)