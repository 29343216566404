import React, { memo, useState } from 'react'
import {
    TableCell,
    Paper,
    StyledEngineProvider,
    Table,
    TableBody,
    TableContainer,
    TableHead,
    TableRow,
} from "@mui/material";
import CollapsibleTable from '../../../../components/TableBody/CollapsibleTable';
import EditAttendance from '../SiteEmployees/EditAttendance';

const EmployeeAttendanceReport = (props: any) => {

    const { EmpAttendanceReportData } = props;

    const [showModal, setShowModal] = useState(false);
    const [employeeDetail, setEmployeeDetail] = useState({
        id: '',
        site_id: '',
    });
    const handleEditAttendance = (id: string, siteId: string) => {
        setEmployeeDetail({ id: id, site_id: siteId });
        setShowModal(true);
    }

    return (
        <div>
            <StyledEngineProvider>
                <TableContainer component={Paper} className="form-outers">
                    <Table aria-label="collapsible table">
                        <TableHead className='topheader'>
                            <TableRow>
                                <TableCell />
                                <TableCell align="left">Employee Name</TableCell>
                                <TableCell align="left">Contact Number</TableCell>
                                <TableCell align="left">Job Title</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody className='tableInnerPanel'>
                            {EmpAttendanceReportData?.response?.map((row: any) => (
                                <CollapsibleTable
                                    selectedColumns={[
                                        "name",
                                        "contact",
                                        "job_title",
                                    ]}
                                    data={row}
                                    subTableData={[
                                        {
                                            title: 'Employee Attendance Status',
                                            key: "attendance_status",
                                            class: "attendance_status_detail",
                                            data: [
                                                { title: 'Attendance Status', key: "status" },
                                                { title: 'Shift Name', key: "shift_name" },
                                                { title: 'Date', key: "date" }
                                            ],
                                            isEdit: true,
                                            editFn: (id: string, siteId: string) => { handleEditAttendance(id, siteId) }
                                        }
                                    ]}
                                />
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                {showModal && employeeDetail?.id !== '' &&
                    <EditAttendance
                        open={showModal}
                        handleModel={() => { setShowModal(false); setEmployeeDetail({ id: "", site_id: "" }); props.refetchEmpAttendance(); }}
                        siteInfo={{ id: employeeDetail.site_id, name: "", }}
                        id={employeeDetail?.id}
                    />
                }
            </StyledEngineProvider>
        </div>
    )
}

export default memo(EmployeeAttendanceReport);