import { Backdrop, Box, Button, Fade, Grid, Modal, Paper, StyledEngineProvider, Table, TableBody, TableContainer, TableHead, TableRow, Toolbar, Typography } from '@mui/material'
import React, { FC, useCallback, useEffect, useState } from 'react'
import { useCreateDailyLogMutation, useDeleteDailyLogMutation, useEditDailyLogMutation, useGetDailyLogByIdQuery } from '../../../../redux-services';
import { TableHeadElement } from '../../../../components/TableBody/TableHeadElement';
import { TableBodyElement } from '../../../../components/TableBody/TableBodyElement';
import { ColumnsType, Order, PageMeta, SortingData } from '../../../../types/CommonTypes';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store/store';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
export interface UserState {
    shift_id: any;
    date: string | any;
}

const tableColumns: ColumnsType[] = [
    {
        id: "id",
        sort: false,
        label: "S.No.",
    },
    {
        id: "username",
        sort: false,
        label: "Employee Name",
    },
    {
        id: "phone",
        sort: false,
        label: "Contact No",
    },
    {
        id: "attendance_status",
        sort: false,
        label: "Current Status",
    },
    {
        id: "attendance_status",
        sort: false,
        label: "Attendance",
    },
    {
        id: "action",
        sort: false,
        label: "Add Comment",
        style: { width: "15%" },
    },
];

let initialOrder: string = process.env.REACT_APP_ORDER as string;

const EditAttendance: FC<{ open: boolean, handleModel: any, siteInfo: { id: string, name: string }, id: string }> = ({ open, handleModel, siteInfo, id }) => {
    const initialState: PageMeta = {
        page: 1,
        per_page: Number(process.env.REACT_APP_PER_PAGE),
        searchText: "",
        order: process.env.REACT_APP_ORDER,
        order_by: process.env.REACT_APP_ORDER_BY,

    };
   
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 1000,
        bgcolor: 'background.paper',
        border: '1px solid #000',
        boxShadow: 24,
        borderRadius: 2,
        p: 2,
    };

    const intialState = {
        shift_id: "",
        date: "",
    }

    const [formData, setFormData] = useState<UserState>(intialState);

    const [createDailyLog] = useCreateDailyLogMutation();
    const [editDailyLog] = useEditDailyLogMutation();

    useEffect(() => {
        const today = new Date();

        const year = today.getFullYear();
        const month = String(today.getMonth() + 1).padStart(2, '0'); 
        const day = String(today.getDate()).padStart(2, '0');

        const formattedDate = `${year}-${month}-${day}`;
        setFormData({ ...formData, date: formattedDate });
    }, []);

    const [orderBy, setOrderBy] = useState<keyof SortingData>(
        process.env.REACT_APP_ORDER_BY as keyof SortingData
    );
    const [order, setOrder] = useState<Order>(initialOrder as Order);
    const [userList, setUserList] = useState<PageMeta>(initialState);
    const siteOrgId = useSelector((state: RootState) => state.auth.siteOrgId);

    const { data: DailyAttendanceLog } = useGetDailyLogByIdQuery(id, {
        skip: !id,
    });

    const [deleteDailyLog, deleteResult] = useDeleteDailyLogMutation();

    useEffect(() => {
        if (deleteResult?.isSuccess && deleteResult?.data?.status === 200) {
            handleModel(false);
        }
    }, [deleteResult])

    const handleRequestSort = (
        event: React.MouseEvent<unknown>,
        property: keyof SortingData
    ) => {
        const isAsc = orderBy === property && order === "asc";
        const newOrder = isAsc ? "desc" : "asc";
        setOrder(newOrder);
        setOrderBy(property);
        setUserList({
            ...userList,
            order: newOrder,
            order_by: property,
        });
    };

    const createSortHandler = (
        event: React.MouseEvent<unknown>,
        property: keyof SortingData
    ) => {
        if (property !== ("action" as keyof SortingData)) {
            handleRequestSort(event, property);
        }
    };

    const updateAttendanceStatus = (id: string, daily_log_id: string | any, status: boolean) => {
        const findActualStatus = DailyAttendanceLog?.response?.length > 0 && DailyAttendanceLog?.response.find((elem: any) => elem?.daily_log_id === daily_log_id);
        if (findActualStatus?.attendance_status === status) {
            deleteDailyLog(daily_log_id);
        } else {
            let date = new Date(formData.date);
            let currentTime = new Date();
            date.setHours(currentTime.getHours());
            date.setMinutes(currentTime.getMinutes());
            date.setSeconds(currentTime.getSeconds());
            date.setMilliseconds(currentTime.getMilliseconds());
            let unixTimeStamp = date.getTime();

            const createData = {
                site_id: siteInfo?.id,
                shift_id: formData.shift_id?._id,
                employee_id: id,
                daily_log_id: daily_log_id ?? '',
                status: status,
                date: unixTimeStamp,
                comment: '',
                organization_id: siteOrgId,
            };
            createDailyLog(createData);
        }
    }

    const handleEditComment = useCallback((id: string, comment: any) => {
        if (id !== "") {
            editDailyLog({ id: id, comment: comment });
        }
    }, []);

    return (
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
            >
                <Fade in={open}>
                    <Box sx={style} className='form-outers karuModal'>
                        <div className='table'>
                            <Paper className="table-outers">
                                <Toolbar style={{ display: "flex", justifyContent: "space-between" }}>
                                    <Typography id="transition-modal-title" variant='h5' className='inner-headings' component="h2">
                                        Edit Attendance Log
                                    </Typography>
                                    <HighlightOffIcon className="closeicon" onClick={() => { handleModel({ status: false, data: [] }) }} />
                                </Toolbar>
                                <StyledEngineProvider>
                                    <TableContainer component={Paper} className="table-outers">
                                        <Table
                                            sx={{ minWidth: 650 }}
                                            size="small" aria-label="a dense table"
                                        >
                                            <TableHead>
                                                <TableRow>
                                                    <TableHeadElement
                                                        setHead={tableColumns ? tableColumns : []}
                                                        order={order}
                                                        orderBy={orderBy}
                                                        sortFn={(event: any, id: string) =>
                                                            createSortHandler(event, id as keyof SortingData)
                                                        }
                                                    />
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                <TableBodyElement
                                                    selectedColumns={[
                                                        "username",
                                                        "phone",
                                                        'attendance_status',
                                                    ]}
                                                    setData={DailyAttendanceLog?.response ? DailyAttendanceLog?.response : []}
                                                    radioAttendance={(id: string, daily_log_id: string | any, status: boolean) => {
                                                        updateAttendanceStatus(id, daily_log_id, status);
                                                    }}
                                                    editCommentFn={(id: string, comment: string) => handleEditComment(id, comment)}
                                                    pageData={{
                                                        limit: DailyAttendanceLog?.response?.length ?? initialState.per_page,
                                                        page: 0,
                                                    }}
                                                />
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </StyledEngineProvider>
                            </Paper>
                        </div>
                        <Grid item xs={12} mt={3}>
                            <Button
                                variant="contained"
                                color="secondary"
                                className="close-btn"
                                onClick={() => handleModel(false)}
                            >
                                Cancel
                            </Button>
                        </Grid>
                    </Box>
                </Fade>
            </Modal>
    )
}

export default EditAttendance