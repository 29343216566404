import {  useState } from "react";
import { Box, Button, Container, TextField, Typography } from "@mui/material";
import { useForgotPasswordMutation } from "../../redux-services";
import AlertBox from "../../components/AlertBox";
import { useNavigate } from 'react-router-dom';

export interface PasswordType {
    email: string;
}

const ForgotPassword = () => {
    const initialState = {
        email: "",
    };
    const navigate = useNavigate()
    const [forgotPassword] = useForgotPasswordMutation();
    const [formData, setFormData] = useState<PasswordType>(initialState);
    const [validationError, setValidationError] = useState<string | null>(null);

    const validateEmail = (email: string) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };
    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const email = formData.email.toLowerCase();
        if (!validateEmail(email)) {
            setValidationError("Please enter a valid email address.");
            return;
        }
        forgotPassword({ email }).then((result) => {
            if ('error' in result) {
                setValidationError('Enter correct Email');
            } else {
                setFormData({ email: "" }); 
            }
        });
    };
    const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormData((prev) => ({ ...prev, [name]: value }));
        setValidationError(null);
    };
    const handleBackButtonClick = () => {
        navigate(-1)
    }
    return (
        <Box className="login-container">

            <Container component="main" maxWidth="sm">
                <Box className="text-center">
                    <img
                        src="/img/logo.png"
                        alt="white_logo"
                        className="web-logo"
                    />
                </Box>
                <AlertBox />
                <Box className="loginBox"
                    sx={{
                        boxShadow: 3,
                        borderRadius: 2,
                        px: 4,
                        py: 6,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                    }}
                >
                        <Typography className="inner-headings" component="h1" variant="h5">
                            Reset Password
                        </Typography>
                    <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1, width: '80%' }}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label="Enter your Email Id"
                            name="email"
                            type="email"
                            autoComplete="email"
                            autoFocus
                            value={formData.email}
                            onChange={handleOnChange}
                        />
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', gap: '1rem' }}>
                            <Button
                                type="button"
                                fullWidth
                                className="secondary-btn py-3"
                                variant="contained"
                                sx={{ mt: 3, mb: 2, flex: 1 }}
                                onClick={handleBackButtonClick}
                            >
                                Back
                            </Button>
                            <Button
                                type="submit"
                                fullWidth
                                className="secondary-btn py-3"
                                variant="contained"
                                sx={{ mt: 3, mb: 2, flex: 1 }}
                            >
                                Submit
                            </Button>
                        </Box>
                    </Box>
                </Box>
            </Container>
        </Box>
    );
};

export default ForgotPassword;
