import { Box, Button, List, ListItem, Typography } from '@mui/material';
import React, { memo, useCallback, useEffect, useState } from 'react'
import EditIcon from '@mui/icons-material/Edit';
import { MachineDataState } from '../../../types/CommonTypes';
import { useGetMachineInfoByIdQuery } from '../../../redux-services';
import AddEditMachineDetail from './AddEditMachineDetail';


const MachineDetail: React.FC<{ paramId: string }> = ({ paramId }) => {

    const initialState: MachineDataState = {
        site_name: "",
        name: "",
        machine_type: "",
        security_amount: "",
        updated_images: [],
        machinetypeData: [],
        siteData: [],
        company: "",
        machine_model: "",
        number_plate: '',
        engine_run_hour: '',
        purchase_date: "",
        manufacturing_date: "",
        tank_capacity: "",
        volume_capacity: "",
        is_rented: false,
        is_loanable: false,
        financer_name: "",
        monthly_installment: "",
        loan_duration: 0,
        rented_amount: 0,
        images: [],
        image_url: [],
        encodedUrl: [],
        insurance_amount: 0,
        expiry_date: 0
    };
    const [open, setOpen] = useState<boolean>(false);
    const [isRented, setIsRented] = useState<boolean>(false);
    const [isLoanable, setIsLoanble] = useState<boolean>(false);
    const [machineData, setMachineData] = useState<MachineDataState>(initialState);

    const { data: getData } = useGetMachineInfoByIdQuery(paramId, {
        skip: !paramId,
    });

    useEffect(() => {
        if (getData?.status === 200) {
            let data = getData?.response;
            let purchaseDate = "";
            if (data?.purchase_date) {
                const timestamp = data.purchase_date;
                if (!isNaN(timestamp)) {
                    purchaseDate = new Date(timestamp)?.toISOString()?.split('T')[0];
                }
            }
            data = { ...data, paramId, site_name: data?.siteData[0]?.name, machine_type: data?.machinetypeData?.name, purchase_date: purchaseDate }
            setMachineData(data)
            let open = data?.is_rented
            let loanOpen = data?.is_loanable
            setIsRented(open)
            setIsLoanble(loanOpen)

        }
    }, [getData]);

    const handleModel = useCallback((isOpen: boolean) => {
        setOpen(isOpen);
    }, []);
    return (
        <>
            <Box className="heading-bar">
                <Box className="action-icons">
                    <List>
                        <ListItem>
                            <Button onClick={() => handleModel(true)}><EditIcon /></Button>
                        </ListItem>
                    </List>
                </Box>
            </Box>
            <Box className="machine-details">
                <List>
                    <ListItem>
                        <Typography variant='h5'>{"Site Name"}</Typography>
                        <Box className="machine-detail-value">{machineData?.site_name}</Box>
                    </ListItem>
                    <ListItem>
                        <Typography variant='h5'>{"Machine Type"}</Typography>
                        <Box className="machine-detail-value">{machineData?.machine_type}</Box>
                    </ListItem>
                    <ListItem>
                        <Typography variant='h5'>{"Company"}</Typography>
                        <Box className="machine-detail-value">{machineData?.company}</Box>
                    </ListItem>
                    <ListItem>
                        <Typography variant='h5'>{"Machine Model"}</Typography>
                        <Box className="machine-detail-value">{machineData?.machine_model}</Box>
                    </ListItem>
                    <ListItem>
                        <Typography variant='h5'>{"Number Plate"}</Typography>
                        <Box className="machine-detail-value">{machineData?.number_plate}</Box>
                    </ListItem>
                    <ListItem>
                        <Typography variant='h5'>{"Engine Run Hour"}</Typography>
                        <Box className="machine-detail-value">{machineData?.engine_run_hour}</Box>
                    </ListItem>
                    <ListItem>
                        <Typography variant='h5'>{"Purchase Date"}</Typography>
                        <Box className="machine-detail-value">{machineData?.purchase_date}</Box>
                    </ListItem>
                    <ListItem>
                        <Typography variant='h5'>{"Tank Capacity"}</Typography>
                        <Box className="machine-detail-value">{machineData?.tank_capacity}</Box>
                    </ListItem>
                    <ListItem>
                        <Typography variant='h5'>{"Volume Capacity(m³)"}</Typography>
                        <Box className="machine-detail-value">{machineData?.volume_capacity}</Box>
                    </ListItem>

                    {isRented && (
                        <>
                            <ListItem>
                                <Typography variant='h5'>{"Rented Amount"}</Typography>
                                <Box className="machine-detail-value">{machineData?.rented_amount}</Box>
                            </ListItem>
                            <ListItem>
                                <Typography variant='h5'>{"Security Amount"}</Typography>
                                <Box className="machine-detail-value">{machineData?.security_amount}</Box>
                            </ListItem>
                        </>
                    )}

                    {isLoanable && (
                        <>
                            <ListItem>
                                <Typography variant='h5'>{"Financer Name"}</Typography>
                                <Box className="machine-detail-value">{machineData?.financer_name}</Box>
                            </ListItem>
                            <ListItem>
                                <Typography variant='h5'>{"Monthly Installments"}</Typography>
                                <Box className="machine-detail-value">{machineData?.monthly_installment}</Box>
                            </ListItem>
                            <ListItem>
                                <Typography variant='h5'>{"Loan Duration"}</Typography>
                                <Box className="machine-detail-value">{machineData?.loan_duration}</Box>
                            </ListItem>

                        </>
                    )}

                </List>
                {/* {open && <OrganizationAddEditModal
                    open={open}
                    machineData={machineData as any}
                    handleModel={handleModel}
                />} */}
                {open &&
                    <AddEditMachineDetail
                        open={open}
                        handleModel={handleModel}
                        machineData={machineData}
                    />
                }
            </Box>
        </>
    )
}

export default memo(MachineDetail)