import {
  Alert,
  Backdrop,
  Box,
  Button,
  Fade,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Modal,
  Paper,
  Select,
  StyledEngineProvider,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { FC, useCallback, useEffect, useState } from "react";
import {
  useCreateDailyLogMutation,
  useCreateDuplicateLogMutation,
  useGetSitesByIdQuery,
  useMarkPreviousAttendanceMutation,
} from "../../../../redux-services";
import { TableHeadElement } from "../../../../components/TableBody/TableHeadElement";
import {
  ColumnsType,
  Order,
  PageMeta,
  SortingData,
} from "../../../../types/CommonTypes";
import { TableBodyElement } from "../../../../components/TableBody/TableBodyElement";
import { useParams } from "react-router-dom";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

export interface UserState {
  date: string;
  shift_id: any;
  site_id: string;
}

const tableColumns: ColumnsType[] = [
  {
    id: "id",
    sort: false,
    label: "S.No.",
  },
  {
    id: "username",
    sort: false,
    label: "Employee Name",
  },
  {
    id: "phone",
    sort: false,
    label: "Contact No",
  },
  {
    id: "attendance_status",
    sort: false,
    label: "Current Status",
  },
  {
    id: "attendance_status",
    sort: false,
    label: "Attendance",
  },
  {
    id: "action",
    sort: false,
    label: "Comment",
    style: { width: "15%" },
  },
];

let initialOrder: string = process.env.REACT_APP_ORDER as string;

const DuplicateAttendance: FC<{
  open: boolean;
  handleModel: any;
  siteId: string;
  setAttendanceState: any;
}> = ({ open, handleModel, siteId, setAttendanceState }) => {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 1000,
    bgcolor: "background.paper",
    border: "1px solid #000",
    boxShadow: 24,
    borderRadius: 2,
    p: 2,
  };
  console.log("lop");
  const initialState: PageMeta = {
    page: 1,
    per_page: Number(process.env.REACT_APP_PER_PAGE),
    searchText: "",
    order: process.env.REACT_APP_ORDER,
    order_by: process.env.REACT_APP_ORDER_BY,
  };

  const intialState = {
    date: "",
    shift_id: "",
    site_id: siteId,
  };
  let { id: site } = useParams();

  const { data: getData } = useGetSitesByIdQuery(site);

  const [formData, setFormData] = useState<UserState>(intialState);
  const [showTable, setShowTable] = useState(false);

  const [showError, setShowError] = useState(false);

  const [showAlert, setShowAlert] = useState(false);
  const [alertMsg, setAlertMsg] = useState("");

  const [createDuplicateLog, result] = useCreateDuplicateLogMutation();
  const [markPreviousAttendance, resultMarkPrevious] =
    useMarkPreviousAttendanceMutation();
  const [createDailyLog] = useCreateDailyLogMutation();

  const [orderBy, setOrderBy] = useState<keyof SortingData>(
    process.env.REACT_APP_ORDER_BY as keyof SortingData
  );
  const [order, setOrder] = useState<Order>(initialOrder as Order);
  const [rowsPerPage, setRowsPerPage] = useState<number>(
    initialState.per_page as number
  );
  const [userList, setUserList] = useState<PageMeta>(initialState);
  const [tableData, setTableData] = useState<any[]>([]);

  const [fetchDate, setFetchDate] = useState<{ date: string; shift: string }>({
    date: "",
    shift: "",
  });

  useEffect(() => {
    if (
      resultMarkPrevious?.isSuccess &&
      resultMarkPrevious?.data?.status === 200
    ) {
      let today = new Date(formData.date);
      const year = today.getFullYear() ?? " ";
      const month = (today.getMonth() + 1).toString().padStart(2, "0") ?? " ";
      const day = today.getDate().toString().padStart(2, "0") ?? " ";

      const dateString = `${year}-${month}-${day}`;
      setAttendanceState({
        select_date: dateString,
        shift_id: formData.shift_id,
      });
      handleModel(false);
    }
  }, [resultMarkPrevious]);

  useEffect(() => {
    if (result?.isSuccess && result?.data?.status === 200) {
      setShowTable(true);
      setTableData(result?.data?.response);
      if (result?.data?.response?.length > 0) {
        let date = dateFormatter(result?.data?.response[0]?.createdAt);
        setFetchDate({ date: date, shift: result?.data?.response[0]?.shift });
      }
    }

    if (result?.isSuccess && !result?.data?.response) {
      setShowError(true);
      setShowTable(false);
      setTableData([]);
      setFetchDate({ date: "", shift: "" });
    }
  }, [result]);

  useEffect(() => {
    if (showError) {
      setTimeout(() => {
        setShowError(false);
      }, 5000);
    }
  }, [showError]);

  useEffect(() => {
    if (showAlert) {
      setTimeout(() => {
        setShowAlert(false);
        setAlertMsg("");
      }, 5000);
    }
  }, [showAlert]);

  const dateFormatter = useCallback((unixTimestamp: number) => {
    const date = new Date(unixTimestamp);

    const year = date.getFullYear() ?? " ";
    const month = (date.getMonth() + 1).toString().padStart(2, "0") ?? " ";
    const day = date.getDate().toString().padStart(2, "0") ?? " ";

    const formattedDateTime = `${day}-${month}-${year}`;
    return formattedDateTime;
  }, []);

  const selectChange = (event: any) => {
    setFormData({
      ...formData,
      shift_id: event.target.value as string,
    });
  };
  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof SortingData
  ) => {
    const isAsc = orderBy === property && order === "asc";
    const newOrder = isAsc ? "desc" : "asc";
    setOrder(newOrder);
    setOrderBy(property);
    setUserList({
      ...userList,
      order: newOrder,
      order_by: property,
    });
  };

  const createSortHandler = (
    event: React.MouseEvent<unknown>,
    property: keyof SortingData
  ) => {
    if (property !== ("action" as keyof SortingData)) {
      handleRequestSort(event, property);
    }
  };
  const updateAttendanceStatus = (
    id: string,
    daily_log_id: any,
    status: boolean
  ) => {
    const findPresentRecord =
      tableData?.length > 0 && tableData.find((item: any) => item?._id === id);

    if (findPresentRecord !== null) {
      if (findPresentRecord?.attendance_status === status) {
        let filterTableData = tableData.map((item: any) =>
          item?._id === id ? { ...item, attendance_status: null } : { ...item }
        );
        setShowAlert(true);
        setAlertMsg(
          `The Attendance log is reset for ${findPresentRecord?.username}`
        );
        setTableData(filterTableData);
      } else {
        let filterTableData = tableData.map((item: any) =>
          item?._id === id
            ? { ...item, attendance_status: status }
            : { ...item }
        );
        setTableData(filterTableData);
      }
    } else {
      let filterTableData = tableData.map((item: any) =>
        item?._id === id ? { ...item, attendance_status: status } : { ...item }
      );
      setTableData(filterTableData);
    }
  };

  const getTodayDate = () => {
    const today = new Date();
    const yyyy = today.getFullYear();
    const mm = String(today.getMonth() + 1).padStart(2, "0");
    const dd = String(today.getDate()).padStart(2, "0");
    return `${yyyy}-${mm}-${dd}`;
  };

  const handleMarkCurrAttendance = () => {
    for (const item of tableData) {
      let date = new Date(formData.date);
      let currentTime = new Date();
      date.setHours(currentTime.getHours());
      date.setMinutes(currentTime.getMinutes());
      date.setSeconds(currentTime.getSeconds());
      date.setMilliseconds(currentTime.getMilliseconds());
      let unixTimeStamp = date.getTime();

      const createData = {
        site_id: item?.site_id,
        shift_id: item?.shift_id,
        employee_id: item?.employee_id,
        daily_log_id: item?.daily_log_id ?? "",
        status: item?.status,
        date: unixTimeStamp,
        comment: "",
        organization_id: item?.organization_id,
      };
      console.log(createData, "lopi");
      createDailyLog(createData);
    }
  };

  const handleDateChange = (e: React.ChangeEvent<any>) => {
    e.preventDefault();
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    const today = new Date();
    today.setDate(today.getDate());
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const day = String(today.getDate()).padStart(2, "0");

    const formattedDate = `${year}-${month}-${day}`;
    const formattedData = {
      ...formData,
      shift_id: formData?.shift_id?._id,
      date: formattedDate,
    };
    createDuplicateLog(formattedData);
  };

  const handleMarkPreviousAttendance = (e: any) => {
    e.preventDefault();
    let date = new Date(formData.date);
    let currentTime = new Date();
    date.setHours(currentTime.getHours());
    date.setMinutes(currentTime.getMinutes());
    date.setSeconds(currentTime.getSeconds());
    date.setMilliseconds(currentTime.getMilliseconds());
    let unixTimeStamp = date.getTime();

    if (tableData?.length > 0) {
      const filterStatusNullRecords = tableData.filter(
        (record: any) => record?.attendance_status !== null
      );
      markPreviousAttendance({
        table: filterStatusNullRecords,
        date: unixTimeStamp,
      });
    }
  };

  const handleEditComment = (id: string, comment: any) => {
    let filterTableData = tableData.map((item: any) =>
      item?._id === id ? { ...item, comment: comment } : { ...item }
    );
    setTableData(filterTableData);
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Fade in={open}>
        <Box sx={style} className="form-outers karuModal">
          <div className="table">
            <Paper className="table-outers">
              <Toolbar
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <Typography
                  id="transition-modal-title"
                  variant="h5"
                  className="inner-headings"
                  component="h2"
                >
                  Find Previous Employee Attendance
                </Typography>
                <HighlightOffIcon
                  className="closeicon"
                  onClick={() => handleModel(false)}
                />
                <Box
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <form onSubmit={handleSubmit}>
                    <Box
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        gap: 20,
                      }}
                    >
                      <Grid item xs={12} md={12}>
                        <Tooltip
                          title="Select the Shift Which you want to Find Previous Record."
                          placement="top"
                        >
                          <Box sx={{ minWidth: 180 }}>
                            <FormControl fullWidth size="small">
                              <InputLabel id="demo-simple-select-label">
                                Select Shifts *
                              </InputLabel>
                              <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={formData.shift_id}
                                label="Select Shifts"
                                required
                                onChange={selectChange}
                                renderValue={() => formData.shift_id?.name}
                              >
                                {getData?.response?.shiftNames?.map(
                                  (item: any) => (
                                    <MenuItem value={item} key={item?._id}>
                                      {item?.name}
                                    </MenuItem>
                                  )
                                )}
                              </Select>
                            </FormControl>
                          </Box>
                        </Tooltip>
                      </Grid>
                      <Grid item xs={2}>
                        <Button
                          type="submit"
                          variant="contained"
                          color="primary"
                          className="save-btn"
                          style={{ height: "100%" }}
                        >
                          Find
                        </Button>
                      </Grid>
                    </Box>
                  </form>
                </Box>
              </Toolbar>
              {fetchDate?.date !== "" && (
                <Toolbar>
                  <h3>
                    Previous Employee Attendance Log fetch from this{" "}
                    {fetchDate?.date} date and this {fetchDate?.shift}.
                  </h3>
                </Toolbar>
              )}
              {showAlert && (
                <Alert variant="outlined" severity="success" className="mb-2">
                  {alertMsg}
                </Alert>
              )}
              {showTable && (
                <StyledEngineProvider>
                  <TableContainer component={Paper} className="table-outers">
                    <Table
                      sx={{ minWidth: 650 }}
                      size="small"
                      aria-label="a dense table"
                    >
                      <TableHead>
                        <TableRow>
                          <TableHeadElement
                            setHead={tableColumns ? tableColumns : []}
                            order={order}
                            orderBy={orderBy}
                            sortFn={(event: any, id: string) =>
                              createSortHandler(event, id as keyof SortingData)
                            }
                          />
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableBodyElement
                          selectedColumns={[
                            "username",
                            "phone",
                            "attendance_status",
                          ]}
                          setData={tableData ?? []}
                          radioAttendance={(
                            id: string,
                            daily_log_id: any,
                            status: boolean
                          ) => {
                            updateAttendanceStatus(id, daily_log_id, status);
                          }}
                          editCommentDuplicateFn={(
                            id: string,
                            comment: string
                          ) => handleEditComment(id, comment)}
                          pageData={{
                            limit: tableData?.length ?? rowsPerPage,
                            page: 0,
                          }}
                        />
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <Toolbar
                    style={{ display: "flex", gap: 10, marginTop: "15px" }}
                  >
                    <Box>
                      Please Select the Date For Which You Want to Log the
                      Attendance
                    </Box>
                    <form
                      onSubmit={handleMarkPreviousAttendance}
                      style={{ display: "flex", gap: 10 }}
                    >
                      <Grid item xs={12} md={12}>
                        <TextField
                          fullWidth
                          id="date"
                          name="date"
                          label="Select Date"
                          type="date"
                          required
                          InputLabelProps={{
                            shrink: true,
                          }}
                          inputProps={{
                            max: getTodayDate(),
                          }}
                          size="small"
                          value={formData?.date}
                          onChange={handleDateChange}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Button
                          type="submit"
                          variant="contained"
                          color="primary"
                          onClick={handleMarkCurrAttendance}
                        >
                          Submit
                        </Button>
                      </Grid>
                    </form>
                  </Toolbar>
                </StyledEngineProvider>
              )}
            </Paper>
          </div>
          <Box mt={3}>
            <Grid item xs={12} mt={3}>
              <Button
                variant="contained"
                color="secondary"
                className="close-btn"
                onClick={() => handleModel(false)}
              >
                Close
              </Button>
            </Grid>
          </Box>
        </Box>
      </Fade>
    </Modal>
  );
};

export default DuplicateAttendance;
