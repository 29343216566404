import React, { FC, useCallback, useMemo, useState } from 'react'
import {
    Backdrop,
    Box,
    Button,
    Container,
    Divider,
    Fade,
    Grid,
    Modal,
    Paper,
    StyledEngineProvider,
    Table,
    TableBody,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    Toolbar,
    Tooltip,
    Typography,
    debounce,
} from "@mui/material";
import { TableBodyElement } from '../../../components/TableBody/TableBodyElement';
import { TableHeadElement } from '../../../components/TableBody/TableHeadElement';
import { ColumnsType, Order, PageMeta, SortingData } from '../../../types/CommonTypes';
import { useLocation, useNavigate } from 'react-router-dom';
import ConfirmBox from '../../../components/ConfirmBox/ConfirmBox';
import { SearchElement } from '../../../components/Search/Search';
import "rsuite/dist/rsuite-no-reset.min.css";
import AddEditMachineWorkLog from './AddEditMachineWorkLog';
import { useDeleteMachineWorkLogMutation, useMachineWorkLogListQuery } from '../../../redux-services';
import PreviousMachineLogs from './PreviousMachineLogs';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
const tableColumns: ColumnsType[] = [
    {
        id: "id",
        sort: false,
        label: "S.No.",
    },
    {
        id: "machine_name",
        sort: true,
        label: "Machinery Name",
    },
    {
        id: "user_name",
        sort: true,
        label: "Employee Name",
    },
    {
        id: 'measurement',
        sort: true,
        label: "Measurement",
    },
    {
        id: 'from_reading',
        sort: true,
        label: "From Reading",
    },
    {
        id: 'to_reading',
        sort: true,
        label: "To Reading",
    },
    {
        id: 'earning',
        sort: true,
        label: "Trip Earning",
    },
    {
        id: 'entry_date',
        sort: true,
        label: "Entry Date",
    },
    {
        id: 'action',
        sort: false,
        label: "Action"
    },

];
let initialOrder: string = process.env.REACT_APP_ORDER as string;

const MachineWorkLog: FC<{ open: boolean, id: string, handleMachineLogModel: any, siteId: string, titleData: string, refetch: any }> = ({ id, open, handleMachineLogModel, siteId, titleData, refetch }) => {
    const style = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 1200,
        bgcolor: 'background.paper',
        border: '1px solid #000',
        boxShadow: 24,
        borderRadius: 2,
        p: 2,
    };

    const initialState: PageMeta = {
        page: 1,
        per_page: Number(process.env.REACT_APP_PER_PAGE),
        searchText: "",
        order: process.env.REACT_APP_ORDER,
        order_by: process.env.REACT_APP_ORDER_BY,
    };
    let navigate = useNavigate();

    let query = useLocation();
    const [page, setPage] = useState<number>(1);
    const [orderBy, setOrderBy] = useState<keyof SortingData>(
        process.env.REACT_APP_ORDER_BY as keyof SortingData
    );
    const [order, setOrder] = useState<Order>(initialOrder as Order);
    const [search, setSearch] = useState<string>("");
    const [rowsPerPage, setRowsPerPage] = useState<number>(
        initialState.per_page as number
    );
    const [tollList, setTollList] = useState<PageMeta>(initialState);
    const [dateRange, setDateRange] = useState({
        startDate: "",
        endDate: "",
    })
    const [isClose, setIsClose] = useState<boolean>(false);
    const [showModal, setShowModal] = useState<boolean>(false);
    const [editId, setEditId] = useState<string>('');
    const [Id, setId] = useState<string>("");

    const [showPreviousModal, setShowPreviousModal] = useState(false);

    const DateFormat = useCallback((dateString: any) => {
        const year = dateString.getFullYear();
        const month = String(dateString.getMonth() + 1).padStart(2, '0');
        const day = String(dateString.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    }, []);

    const {data } = useMachineWorkLogListQuery({
        per_page: tollList.per_page ?? rowsPerPage,
        page: query.state ?? tollList.page,
        order: tollList.order ?? order,
        order_by: tollList.order_by ?? orderBy,
        searchText: tollList.searchText ?? search,
        startDate: dateRange.startDate ? dateRange.startDate : "",
        endDate: dateRange.endDate ? dateRange.endDate : "",
        id: id ?? '',
    });
    
    const [deleteMachineWorkLog] = useDeleteMachineWorkLogMutation();

    const handleRequestSort = (
        event: React.MouseEvent<unknown>,
        property: keyof SortingData
    ) => {
        const isAsc = orderBy === property && order === "asc";
        const newOrder = isAsc ? "desc" : "asc";
        setOrder(newOrder);
        setOrderBy(property);
        setTollList({
            ...tollList,
            order: newOrder,
            order_by: property,
        });
    };

    const createSortHandler = (
        event: React.MouseEvent<unknown>,
        property: keyof SortingData
    ) => {
        if (property !== ("action" as keyof SortingData)) {
            handleRequestSort(event, property);
        }
    };

    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number
    ) => {
        setPage(newPage + 1);
        setTollList({ ...tollList, page: newPage + 1 });

        if (query.state !== null) {
            navigate(query.pathname, {});
        }
    };
    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setTollList({
            ...tollList,
            per_page: parseInt(event.target.value),
            page: 1,
        });
        setPage(1);
    };

    const handleModel = useCallback((isOpen: boolean) => {
        setShowModal(isOpen);
    }, []);

    const searchHandler = useMemo(() => {
        return debounce((event: React.ChangeEvent<HTMLInputElement>) => {
            setSearch(event.target.value);
            setTollList({
                page: 1,
                per_page: tollList.per_page,
                order: initialOrder,
                searchText: event.target.value,
            });
        }, 800);
    }, []);

    const confirmBoxClose = (res: boolean) => {
        setIsClose(res);
        if (res) {
            setIsClose(false);
            deleteMachineWorkLog(Id);
        } else {
            setIsClose(res);
        }
    };

    const handleDateChange = (date: any) => {
        if (date !== null) {
            setDateRange({ ...dateRange, startDate: DateFormat(date[0]), endDate: DateFormat(date[1]) })
        } else {
            setDateRange({ ...dateRange, startDate: '', endDate: '' })
        }
    };

    const handlePreviousModal = useCallback((isOpen: boolean) => {
        setShowPreviousModal(isOpen);
    }, []);

    return (
        <React.Fragment>
            <Container maxWidth={false} className='p-0' >
                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    open={open}
                    closeAfterTransition
                    slots={{ backdrop: Backdrop }}
                    slotProps={{
                        backdrop: {
                            timeout: 500,
                        },
                    }}
                >
                    <Fade in={open}>
                        <Box sx={style} className='form-outers karuModal'>
                            <Box style={{ display: "flex", justifyContent: "space-between" }}>
                                <Typography id="transition-modal-title" variant='h5' className='inner-headings' component="h2">
                                    Work Item {titleData}
                                </Typography>
                                <HighlightOffIcon className="closeicon" onClick={() => handleMachineLogModel(false)} />
                                {/* {data?.response?.data?.length > 0 &&
                                    <Tooltip title={`Find Previous Machine Log`} arrow className="me-1 mt-1">
                                        <Button
                                            className="secondary-btn"
                                            variant="contained"
                                            color="primary"
                                            style={{ height: "100%" }}
                                            onClick={() => { setShowPreviousModal(true); }}
                                        >
                                            Find Previous Machine Log
                                        </Button>
                                    </Tooltip>
                                } */}
                            </Box>
                            <Divider sx={{ marginTop: 2, marginBottom: 3 }} />
                            <div>
                                <Paper className="form-outers">
                                    <Toolbar>
                                        <Typography
                                            sx={{ flex: '1 1 40%' }}
                                            variant="h5"
                                            id="tableTitle"
                                            component="div"
                                            className="inner-headings"
                                        >
                                            Machine Work Log
                                        </Typography>
                                        <div className="flex-boxinner worklogs" style={{ width: "100%" }}>
                                            <Typography
                                                sx={{ flex: '1 1 auto' }}
                                                className="datepicker-outer"
                                            >
                                                {/* <DateRangePicker
                                                    ranges={predefinedRanges as any}
                                                    placeholder="Select Date Range"
                                                    showOneCalendar
                                                    onChange={handleDateChange}
                                                /> */}
                                                <SearchElement
                                                    searchFn={searchHandler}
                                                    searchTag={"Search Log..."}
                                                />

                                            </Typography>
                                            <Tooltip title="Add Machine Work Log" arrow className="me-1 mt-1">
                                                <Button
                                                    className="secondary-btn"
                                                    onClick={() => {
                                                        setShowModal(true);
                                                        setEditId('');
                                                    }}
                                                    type="submit"
                                                    variant="contained"
                                                    color="primary"
                                                >
                                                    Add Machine Work Log
                                                </Button>
                                            </Tooltip>
                                        </div>
                                    </Toolbar>
                                    <StyledEngineProvider>
                                        <TableContainer component={Paper} className="form-outers">
                                            <Table
                                                sx={{ minWidth: 650 }}
                                                size="small" aria-label="a dense table"
                                                className="table-outers"
                                            >
                                                <TableHead>
                                                    <TableRow>
                                                        <TableHeadElement
                                                            setHead={tableColumns ? tableColumns : []}
                                                            order={order}
                                                            orderBy={orderBy}
                                                            sortFn={(event, id: string) =>
                                                                createSortHandler(event, id as keyof SortingData)
                                                            }
                                                        />
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    <TableBodyElement
                                                        selectedColumns={[
                                                            "machine_name",
                                                            "user_name",
                                                            "measurement",
                                                            "from_reading",
                                                            "to_reading",
                                                            "earning",
                                                            "entry_date",
                                                        ]}
                                                        setData={data?.response?.data ?? []}
                                                        editFn={(id) => {
                                                            setEditId(id);
                                                            setShowModal(true);
                                                        }}
                                                        deleteFn={(id) => {
                                                            setIsClose(true);
                                                            setId(id);
                                                        }}
                                                        pageData={{
                                                            limit:
                                                                rowsPerPage ?? Number(process.env.REACT_APP_PER_PAGE),
                                                            page: data?.response.page as number,
                                                        }}
                                                    />
                                                </TableBody>
                                            </Table>
                                            <TablePagination
                                                component="div"
                                                count={data?.response.total ?? 0}
                                                page={data?.response.page ?? 0}
                                                rowsPerPageOptions={[20, 50, 100]}
                                                onPageChange={handleChangePage}
                                                rowsPerPage={
                                                    rowsPerPage ?? Number(process.env.REACT_APP_PER_PAGE)
                                                }
                                                onRowsPerPageChange={handleChangeRowsPerPage}
                                            />
                                        </TableContainer>
                                    </StyledEngineProvider>
                                </Paper>
                                <ConfirmBox
                                    title="Machine Work Log Delete Confirmation"
                                    message="Are you sure, you want to delete this Machine Work Log?"
                                    open={isClose}
                                    onClose={confirmBoxClose}
                                />
                            </div>
                            <Grid item xs={12}>
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    className="close-btn"
                                    onClick={() => handleMachineLogModel(false)}
                                    style={{ marginTop: "20px" }}
                                >
                                    Close
                                </Button>
                            </Grid>
                        </Box>
                    </Fade>
                </Modal>
                {showModal &&
                    <AddEditMachineWorkLog
                        open={showModal}
                        handleModel={handleModel}
                        id={editId}
                        WorkItemId={id}
                        refetch={refetch}
                    />
                }
                {showPreviousModal &&
                    <PreviousMachineLogs
                        open={showPreviousModal}
                        handleModel={handlePreviousModal}
                        siteId={siteId ?? ''}
                        workItemId={id ?? ''}
                    />
                }
            </Container>
        </React.Fragment>
    )
}

export default MachineWorkLog