import React, { FC,useEffect, useState } from 'react';
import { TextField, Button, Grid,  Box, FormControl, InputLabel, Select, MenuItem, SelectChangeEvent, Modal, Backdrop, Fade, Typography, Divider, FormControlLabel, Checkbox } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setError, useCreateMachineInfoMutation,  useEditMachineInfoMutation, useGetSitesDetailQuery, useGetMachineByOrgIdQuery } from '../../../redux-services';
import Error from '../../../components/ErrorBox/Error';
import ReusableAddEditMachineInfo from '../ReusableAddEditMahineInfo';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
export interface UserState {
    site_id: any;
    machine_id: any;
    machine_type: any;
    [key: string]: any;
    company: string;
    machine_model: string;
    number_plate: string;
    purchase_date: string;
    manufacturing_date: string;
    tank_capacity: string;
    insurance_amount: string
    volume_capacity: string;
    expiration_date: string;
    isSiteChange: boolean;
    isMachineChange: boolean;
    is_rented: boolean;
    is_loanable: boolean;
    monthly_installment: number | any;
    financer_name: string;
    loan_duration: number;
    rented_amount: number | any;
    security_amount: number | any;
    measurement_unit: string

}
interface FormErrors {
    machine_type?: string;
    company?: string;
    machine_model?: string;
    number_plate?: string;
    purchase_date?: string;
    manufacturing_date?: string;
    tank_capacity?: string;
    volume_capacity?: string;
    insurance_amount?: string;
    expiration_date?: string;
}
const AddEditMachineDetail: FC<{ open: boolean, handleModel: any, machineData?: any | undefined }> = ({ open, handleModel, machineData }) => {

    const { id } = useParams();

    const style = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        bgcolor: 'background.paper',
        border: '1px solid #000',
        boxShadow: 24,
        borderRadius: 2,
        p: 2,
    };

    const intialState = {
        site_id: '',
        machine_id: '',
        machine_type: "",
        company: "",
        machine_model: "",
        number_plate: "",
        expiration_date: "",
        purchase_date: "",
        manufacturing_date: "",
        tank_capacity: "",
        volume_capacity: "",
        isSiteChange: false,
        isMachineChange: false,
        is_rented: false,
        is_loanable: false,
        financer_name: "",
        monthly_installment: 0,
        loan_duration: 0,
        insurance_amount: "",
        rented_amount: 0,
        security_amount: 0,
        measurement_unit: ""

    }
    let dispatch = useDispatch();
    const [formData, setFormData] = useState<UserState>(intialState);
    const [formErrors, setFormErrors] = useState<FormErrors>({});

    const [createMachineInfo, result] = useCreateMachineInfoMutation();
    const [editMachineInfo, editResult] = useEditMachineInfoMutation();
    const SitesData = useGetSitesDetailQuery();
    const orgId = machineData?.machinetypeData?.organization_id ?? "";
    const machineTypeData = useGetMachineByOrgIdQuery(orgId, { skip: !orgId });

    useEffect(() => {
        if (machineData) {
            let data = machineData;
            let machineTypeData = data?.machine_type;
            machineTypeData = {
                _id: (machineTypeData?._id?.length === 0) ? "" : machineTypeData?._id,
                name: (machineTypeData?.name?.length === 0) ? "" : machineTypeData?.name
            }
            let siteIdData = data?.siteData[0];
            let machineIdData = data?.machinetypeData;

            siteIdData = {
                _id: (siteIdData?._id?.length === 0) ? "" : siteIdData?._id,
                name: (siteIdData?.name?.length === 0) ? "" : siteIdData?.name,
            }
          
            setFormData({
                ...formData,
                machine_id: machineIdData ?? [],
                machine_name: machineIdData?._id ?? [],
                site_id: siteIdData ?? [],
                company: data?.company ?? "",
                machine_model: data?.machine_model ?? "",
                number_plate: data?.number_plate ?? "",
                purchase_date: data?.purchase_date ?? "",
                manufacturing_date: data?.manufacturing_date ?? "",
                volume_capacity: data?.volume_capacity ?? "",
                tank_capacity: data?.tank_capacity ?? "",
                is_rented: data?.is_rented ?? false,
                is_loanable: data?.is_loanable ?? false,
                financer_name: data?.financer_name ?? "",
                monthly_installment: data?.monthly_installment ?? "",
                loan_duration: data?.loan_duration ?? "",
                rented_amount: data?.rented_amount ?? 0,
                security_amount: data?.security_amount ?? 0,
                measurement_unit: data?.machinetypeData?.measurement_unit ?? ""
            })
        }
        return () => {
            dispatch(setError([]));
        };
    }, [machineData]);

    if (result.isSuccess && result.data.status === 200) {
        handleModel(false);
    }

    if (editResult.isSuccess && editResult.data.status === 200) {
        handleModel(false);
    }

    const handleSubmit = (e: any) => {
        e.preventDefault();
        dispatch(setError([]));
        const purchaseDateTimestamp = Math.floor(new Date(formData.purchase_date)?.getTime());
        let formattedData = {
            machine_type: formData?.machine_id._id ?? "",
            company: formData?.company ?? "",
            machine_model: formData?.machine_model ?? "",
            number_plate: formData?.number_plate ?? "",
            purchase_date: purchaseDateTimestamp ?? "",
            volume_capacity: Number(formData?.volume_capacity),
            tank_capacity: Number(formData?.tank_capacity),
            site_id: formData.site_id._id !== '' ? formData?.site_id?._id : "",
            isSiteChange: formData?.isSiteChange,
            is_rented: formData?.is_rented ?? false,
            rented_amount: formData?.rented_amount ?? 0,
            is_loanable: formData?.is_loanable ?? 0,
            financer_name: formData?.financer_name ?? "",
            monthly_installment: formData?.monthly_installment ?? 0,
            loan_duration: formData?.loan_duration ?? 0,
            security_amount: formData?.security_amount ?? 0,

            measurement_unit: formData?.measurement_unit ?? "",
            id: id ? id : null,
        }
        id ? editMachineInfo(formattedData) : createMachineInfo(formattedData);
    };
    const validate = (formData: UserState) => {
        const errors: Partial<UserState> = {};
        const regex = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?0-9]+/;
        if (!formData.machine_model.trim()) {
            errors.machine_model = "Machine Model is required!";
        }
        if (!formData.company.trim()) {
            errors.company = "Company is required!";
        }
        if (regex.test(formData.company)) {
            errors.volume_capacity = "Volume Capacity cannot contain special characters!";
        }
        return errors;
    };
    const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, checked } = e.target;

        if (name === 'is_rented' && checked) {
            setFormData((prevState) => ({
                ...prevState,
                is_rented: true,
                is_loanable: false,
            }));
        } else if (name === 'is_loanable' && checked) {
            setFormData((prevState) => ({
                ...prevState,
                is_rented: false,
                is_loanable: true,
            }));
        } else {
            setFormData((prevState) => ({
                ...prevState,
                [name]: checked,
            }));
        }
    };
    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | any>) => {
        e.preventDefault();
        const { name, value } = e.target;

        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: name === "monthly_installment" ? Number(value) : value.trimStart()
        }));

        const errors = validate({ ...formData, [name]: value });
        setFormErrors((prevErrors) => ({
            ...prevErrors,
            [name]: errors[name as keyof UserState]
        }));
    };
    const selectSitesChange = (event: (SelectChangeEvent | any)) => {
        let userData = machineData?.siteData && machineData?.siteData[0];
        if (userData?._id === event.target.value?._id) {
            setFormData({ ...formData, site_id: event.target.value as string, isSiteChange: false });
        } else {
            setFormData({ ...formData, site_id: event.target.value as string, isSiteChange: true });
        }
    };

    const handleCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
        const isChecked = event.target.checked;
        if (isChecked) {
            setFormData({ ...formData, is_rented: isChecked });
        } else {
            setFormData({ ...formData, is_rented: isChecked, rented_amount: 0, security_amount: 0 });
        }
    }

    const selectMachineTypeChange = (event: (SelectChangeEvent | any)) => {
        const selectedValue = event.target.value.name
        const machineDetails = machineTypeData?.data?.response.find((machine: { name: any; }) => machine.name === selectedValue);

        let userData = machineData?.machinetypeData;
        if (userData?._id === event.target.value?._id) {
            setFormData({
                ...formData,
                machine_id: event.target.valueevent.target.value as string,
                isMachineChange: false,
                volume_capacity: "",
                number_plate: "",
                tank_capacity: "",
                measurement_unit: machineDetails?.measurement_unit || ""

            });
        } else {
            setFormData({
                ...formData,
                machine_id: event.target.value as string,
                isMachineChange: true,
                volume_capacity: "",
                number_plate: "",
                tank_capacity: "",
                measurement_unit: machineDetails?.measurement_unit || ""

            });
        }
    };

    return (
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
            >
                <Fade in={open}>
                    <Box sx={style} className='form-outers karuModal'>
                        <Typography id="transition-modal-title" variant='h5' className='inner-headings' component="h2">
                            {id ? 'Edit Machinery' : '  '}
                        </Typography>
                        <HighlightOffIcon className="closeicon" onClick={() => handleModel(false)} />

                        <Box className="modalBody">
                            <Divider sx={{ marginTop: 2, marginBottom: 3 }} />
                            <form onSubmit={handleSubmit}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} md={6}>
                                        <Box sx={{ minWidth: 120 }}>
                                            <FormControl fullWidth size="small">
                                                <InputLabel id="demo-simple-select-label">Sites Names</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    value={formData.site_id}
                                                    label="Sites Name"
                                                    onChange={selectSitesChange}
                                                    renderValue={() => formData.site_id?.name}
                                                >
                                                    {SitesData && SitesData?.data?.response.map(
                                                        (item: any, index: number) => (
                                                            <MenuItem value={item} key={index}>{item?.name}</MenuItem>
                                                        ))}
                                                </Select>
                                            </FormControl>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <Box sx={{ minWidth: 120 }}>
                                            <FormControl fullWidth size="small">
                                                <InputLabel id="demo-simple-select-label">Machine Types*</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    value={formData.machine_i}
                                                    label="Machine Types"
                                                    required
                                                    onChange={selectMachineTypeChange}
                                                    renderValue={() => formData.machine_id?.name}
                                                >
                                                    {machineTypeData && machineTypeData?.data?.response.map(
                                                        (item: any, index: number) => (
                                                            <MenuItem value={item} key={index}>{item?.name}</MenuItem>
                                                        ))}
                                                </Select>
                                            </FormControl>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            required
                                            fullWidth
                                            id="company"
                                            label="Company"
                                            name="company"
                                            type="text"
                                            size='small'
                                            autoComplete="company"
                                            value={formData.company}
                                            onChange={handleChange}
                                            error={!!formErrors.company}
                                            helperText={formErrors.company}
                                        />
                                        <Error current_key="company" />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            required
                                            fullWidth
                                            id="machine_model"
                                            label="Machine Model"
                                            name="machine_model"
                                            type="text"
                                            size='small'
                                            autoComplete="machine_model"
                                            value={formData.machine_model}
                                            onChange={handleChange}
                                            error={!!formErrors.machine_model}
                                            helperText={formErrors.machine_model}
                                        />
                                        <Error current_key="machine_model" />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            fullWidth
                                            id="purchase_date"
                                            label="Purchase Date"
                                            name="purchase_date"
                                            className="calendradate"
                                            type="date"
                                            size='small'
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            autoComplete="purchase_date"
                                            value={formData.purchase_date}
                                            onChange={handleChange}
                                            error={!!formErrors.purchase_date}
                                            helperText={formErrors.purchase_date}
                                        />
                                        <Error current_key="purchase_date" />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={formData.is_rented}
                                                    onChange={handleCheckboxChange}
                                                    name="is_rented"
                                                />
                                            }
                                            label={<Typography sx={{ color: 'white' }}>Rented Machine</Typography>}
                                        />
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={formData.is_loanable}
                                                    onChange={handleCheckboxChange}
                                                    name="is_loanable"
                                                />
                                            }
                                            label={<Typography sx={{ color: 'white' }}>Loanable</Typography>}
                                        />
                                    </Grid>
                                    {formData?.is_rented &&
                                        <Grid item xs={12} md={12}>
                                            <TextField
                                                fullWidth
                                                required
                                                id="rented_amount"
                                                label="Rented Amount"
                                                name="rented_amount"
                                                type="number"
                                                size='small'
                                                inputProps={{
                                                    min: 1,
                                                }}
                                                autoComplete="rented_amount"
                                                value={formData.rented_amount}
                                                onChange={handleChange}
                                            />
                                            <Error current_key="rented_amount" />
                                        </Grid>
                                    }

                                    {formData?.is_rented &&
                                        <Grid item xs={12} md={12}>
                                            <TextField
                                                fullWidth
                                                id="security_amount"
                                                label="Security Amount"
                                                name="security_amount"
                                                type="number"
                                                size='small'
                                                inputProps={{
                                                    min: 1,
                                                }}
                                                autoComplete="security_amount"
                                                value={formData.security_amount}
                                                onChange={handleChange}
                                            />
                                            <Error current_key="security_amount" />
                                        </Grid>
                                    }
                                    {formData?.is_loanable &&
                                        <Grid item xs={12} md={12}>
                                            <TextField
                                                fullWidth
                                                required
                                                id="financer_name"
                                                label="Financer Name"
                                                name="financer_name"
                                                type="string"
                                                size='small'
                                                inputProps={{
                                                    min: 1,
                                                }}
                                                autoComplete="financer_name"
                                                value={formData.financer_name}
                                                onChange={handleChange}
                                            />
                                            <Error current_key="financer_name" />
                                        </Grid>
                                    }
                                    {formData?.is_loanable &&
                                        <Grid item xs={12} md={12}>
                                            <TextField
                                                fullWidth
                                                required
                                                id="loan_duration"
                                                label="Loan Duration"
                                                name="loan_duration"
                                                type="number"
                                                size='small'
                                                inputProps={{
                                                    min: 1,
                                                }}
                                                autoComplete="loan_duration"
                                                value={formData.loan_duration}
                                                onChange={handleChange}
                                            />
                                            <Error current_key="loan_duration" />
                                        </Grid>
                                    }
                                    {formData?.is_loanable &&
                                        <Grid item xs={12} md={12}>
                                            <TextField
                                                fullWidth
                                                required
                                                id="monthly_installment"
                                                label="Monthly Installment"
                                                name="monthly_installment"
                                                type="number"
                                                size='small'
                                                inputProps={{
                                                    min: 1,
                                                }}
                                                autoComplete="monthly_installment"
                                                value={formData.monthly_installment}
                                                onChange={handleChange}
                                            />
                                            <Error current_key="loan_duration" />
                                        </Grid>
                                    }
                                    <ReusableAddEditMachineInfo
                                        machineType={formData.machine_id}
                                        formData={formData}
                                        handleChange={handleChange}
                                        formErrors={formErrors}
                                    />

                                    <Grid item xs={12}>
                                        <Button
                                            type="submit"
                                            variant="contained"
                                            color="primary"
                                            className="save-btn"
                                            onSubmit={handleSubmit}
                                            style={{ marginRight: '10px' }}
                                            disabled={Object.values(formErrors).some(error => !!error)}
                                        >
                                            Submit
                                        </Button>
                                        <Button
                                            variant="contained"
                                            color="secondary"
                                            className="close-btn"
                                            onClick={() => handleModel(false)}
                                        >
                                            Cancel
                                        </Button>
                                    </Grid>
                                </Grid>
                            </form>
                        </Box>

                    </Box>
                </Fade>
            </Modal>
    );
};

export default AddEditMachineDetail;
