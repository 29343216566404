import { Backdrop, Box, Button, Fade, FormControl, Grid, InputLabel, MenuItem, Modal, Paper, Select, SelectChangeEvent, StyledEngineProvider, Table, TableBody, TableContainer, TableHead, TableRow, TextField, Toolbar, Typography } from '@mui/material'
import React, { FC, useCallback, useEffect, useState } from 'react'
import { useCreateDailyLogMutation, useDeleteDailyLogMutation, useEditDailyLogMutation, useFindEmployeeListQuery, useGetSitesByIdQuery } from '../../../../redux-services';
import { TableHeadElement } from '../../../../components/TableBody/TableHeadElement';
import { TableBodyElement } from '../../../../components/TableBody/TableBodyElement';
import { ColumnsType, Order, PageMeta, SortingData } from '../../../../types/CommonTypes';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store/store';
import { useParams } from 'react-router-dom';

export interface UserState {
  shift_id: any;
  date: string ;
}

const tableColumns: ColumnsType[] = [
  {
    id: "id",
    sort: false,
    label: "S.No.",
  },
  {
    id: "username",
    sort: false,
    label: "Employee Name",
  },
  {
    id: "phone",
    sort: false,
    label: "Contact No",
  },
  {
    id: "attendance_status",
    sort: false,
    label: "Current Status",
  },
  {
    id: "attendance_status",
    sort: false,
    label: "Attendance",
  },
  {
    id: "action",
    sort: false,
    label: "Add Comment",
    style: { width: "15%" },
  },
];

let initialOrder: string = process.env.REACT_APP_ORDER as string;

const EmpMarkAttendance: FC<{ open: boolean, handleModel: any, siteInfo: { id: string, name: string } }> = ({ open, handleModel, siteInfo }) => {
  const initialState: PageMeta = {
    page: 1,
    per_page: Number(process.env.REACT_APP_PER_PAGE),
    searchText: "",
    order: process.env.REACT_APP_ORDER,
    order_by: process.env.REACT_APP_ORDER_BY,

  };
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 1000,
    bgcolor: 'background.paper',
    border: '1px solid #000',
    boxShadow: 24,
    borderRadius: 2,
    p: 2,
  };

  const intialState = {
    shift_id: "",
    date: "",
  }
  let { id: siteId } = useParams();

  const { data: getData } = useGetSitesByIdQuery(siteId);

  const [formData, setFormData] = useState<UserState>(intialState);
  const [showTable, setShowTable] = useState(false);
  const [createDailyLog] = useCreateDailyLogMutation();
  const [editDailyLog] = useEditDailyLogMutation();

  useEffect(() => {
    const today = new Date();

    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0'); 
    const day = String(today.getDate()).padStart(2, '0');

    const formattedDate = `${year}-${month}-${day}`;
    setFormData({ ...formData, date: formattedDate });
  }, []);

  const [orderBy, setOrderBy] = useState<keyof SortingData>(
    process.env.REACT_APP_ORDER_BY as keyof SortingData
  );
  const [order, setOrder] = useState<Order>(initialOrder as Order);
  const [rowsPerPage] = useState<number>(
    initialState.per_page as number
  );
  const [userList, setUserList] = useState<PageMeta>(initialState);
  const siteOrgId = useSelector((state: RootState) => state.auth.siteOrgId);

  const {data, refetch } = useFindEmployeeListQuery({
    select_date: formData.date,
    site_id: siteInfo?.id,
    shift_id: formData?.shift_id?._id,
    organization_id: siteOrgId
  }, { skip: !showTable });

  const [deleteDailyLog] = useDeleteDailyLogMutation();

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof SortingData
  ) => {
    const isAsc = orderBy === property && order === "asc";
    const newOrder = isAsc ? "desc" : "asc";
    setOrder(newOrder);
    setOrderBy(property);
    setUserList({
      ...userList,
      order: newOrder,
      order_by: property,
    });
  };

  const createSortHandler = (
    event: React.MouseEvent<unknown>,
    property: keyof SortingData
  ) => {
    if (property !== ("action" as keyof SortingData)) {
      handleRequestSort(event, property);
    }
  };

  const getTodayDate = () => {
    const today = new Date();
    const yyyy = today.getFullYear();
    const mm = String(today.getMonth() + 1).padStart(2, '0'); 
    const dd = String(today.getDate()).padStart(2, '0');
    return `${yyyy}-${mm}-${dd}`;
  };

  const selectChange = (event: (SelectChangeEvent)) => {
    setFormData({
      ...formData,
      shift_id: event.target.value
    });
    setShowTable(false);
  };
  const handleDateChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    e.preventDefault();
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value
    }));
    setShowTable(false);
  };

  const updateAttendanceStatus = (id: string, daily_log_id: string , status: boolean) => {
    const findActualStatus = data?.response?.data?.length > 0 && data?.response?.data.find((elem: any) => elem?.daily_log_id === daily_log_id);
    if (findActualStatus?.attendance_status === status) {
      deleteDailyLog(daily_log_id);
    } else {
      let date = new Date(formData.date);
      let currentTime = new Date();
      date.setHours(currentTime.getHours());
      date.setMinutes(currentTime.getMinutes());
      date.setSeconds(currentTime.getSeconds());
      date.setMilliseconds(currentTime.getMilliseconds());
      let unixTimeStamp = date.getTime();

      const createData = {
        site_id: siteInfo?.id,
        shift_id: formData.shift_id?._id,
        employee_id: id,
        daily_log_id: daily_log_id ?? '',
        status: status,
        date: unixTimeStamp,
        comment: '',
        organization_id: siteOrgId,
      };
      createDailyLog(createData);
    }
  }

  const handleShowTable = (e: any) => {
    e.preventDefault();
    setShowTable(true);
    if (showTable) {
      refetch();
    }
  };

  const handleEditComment = useCallback((id: string, comment: any) => {
    if (id !== "") {
      editDailyLog({ id: id, comment: comment });
    }
  }, []);

  return (
    
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={style} className='form-outers karuModal'>
            <div className='table'>
              <Paper className="table-outers">
                <Toolbar style={{ display: "flex", justifyContent: "space-between" }}>
                  <Typography id="transition-modal-title" variant='h5' className='inner-headings' component="h2">
                    Employee Attendance
                  </Typography>
                  <Box style={{ display: "flex", justifyContent: "space-between" }}>
                    <form onSubmit={handleShowTable}>
                      <Grid container spacing={3} item xs={12}>
                        <Grid item md={5}>
                          <TextField
                            fullWidth
                            id="date"
                            name="date"
                            label="Attendance Date"
                            type="date"
                            required
                            InputLabelProps={{
                              shrink: true,
                            }}
                            inputProps={{
                              max: getTodayDate(),
                            }}
                            size='small'
                            value={formData?.date}
                            onChange={handleDateChange}
                          />
                        </Grid>
                        <Grid item md={5}>
                          <Box sx={{ minWidth: 150 }}>
                            <FormControl fullWidth size='small'>
                              <InputLabel id="demo-simple-select-label">Select Shifts *</InputLabel>
                              <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={formData.shift_id}
                                label="Select Shifts"
                                required
                                onChange={selectChange}
                                renderValue={() => formData.shift_id?.name}
                              >
                                {getData?.response?.shiftNames?.map(
                                  (item: any, index: number) => (
                                    <MenuItem value={item} key={index}>{item?.name}</MenuItem>
                                  ))}
                              </Select>
                            </FormControl>
                          </Box>
                        </Grid>
                        <Grid item xs={2}>
                          <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            className="save-btn"
                            style={{ height: '100%' }}
                          >
                            Fetch
                          </Button>
                        </Grid>
                      </Grid>
                    </form>
                  </Box>
                </Toolbar>
                {
                  showTable &&
                  <StyledEngineProvider>
                    <TableContainer component={Paper} className="table-outers">
                      <Table
                        sx={{ minWidth: 650 }}
                        size="small" aria-label="a dense table"
                      >
                        <TableHead>
                          <TableRow>
                            <TableHeadElement
                              setHead={tableColumns || []}
                              order={order}
                              orderBy={orderBy}
                              sortFn={(event: any, id: string) =>
                                createSortHandler(event, id as keyof SortingData)
                              }
                            />
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <TableBodyElement
                            selectedColumns={[
                              "username",
                              "phone",
                              'attendance_status',
                            ]}
                            setData={data?.response.data ? data?.response.data : []}
                            radioAttendance={(id: string, daily_log_id: string , status: boolean) => {
                              updateAttendanceStatus(id, daily_log_id, status);
                            }}
                            editCommentFn={(id: string, comment: string) => handleEditComment(id, comment)}
                            pageData={{
                              limit: data?.response.data?.length ?? rowsPerPage,
                              page: 0,
                            }}
                          />
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </StyledEngineProvider>
                }
              </Paper>
            </div>
            <Grid item xs={12} mt={3} className="empmarkAttendance">
              <Button
                variant="contained"
                color="secondary"
                className="close-btn"
                onClick={() => handleModel(false)}
              >
                Close
              </Button>
            </Grid>
          </Box>
        </Fade>
      </Modal>
  )
}

export default EmpMarkAttendance