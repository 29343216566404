import React, { FC, memo, useEffect, useState } from 'react';
import { TextField, Button, Grid, Box, FormControl, InputLabel, Select, MenuItem, Divider, Typography, Fade, Backdrop, Modal, SelectChangeEvent, FormControlLabel, Checkbox } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { setError, useCreateMachineInfoMutation, useGetMachineByOrgIdQuery, useGetMachineInfoByIdQuery } from '../../../../redux-services';
import Error from '../../../../components/ErrorBox/Error';
import ReusableAddEditMachineInfo from '../../../MachineInfo/ReusableAddEditMahineInfo';
import { RootState } from '../../../../store/store';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

export interface UserState {
    site_id: any;
    [key: string]: any;
    machine_type: any;
    company: string;
    machine_model: string;
    measurement_unit: string;
    number_plate: string;
    purchase_date: string;
    expiration_date: string;
    manufacturing_date: string;
    tank_capacity: string;
    insurance_amount: string
    volume_capacity: string;
    isSiteChange: boolean;
    is_rented: boolean;
    rented_amount: number | any;
    security_amount: number | any;
    error: any
    vehicle_paper: any[];
    vehicle_paper_url: any[];
    vehicle_rc: any[];
    vehicle_rc_url: any[];
    insurance: any[];
    insurance_url: any[];
    loan_paper: any[];
    loan_paper_url: any[];
    images: any[];
    image_url: any[];
    loan_duration: number | any;
    financer_name: string | any;
    monthly_installment: number | any;
}
interface FormErrors {
    machine_type?: string;
    company?: string;
    machine_model?: string;
    number_plate?: string;
    purchase_date?: string;
    manufacturing_date?: string;
    tank_capacity?: string;
    volume_capacity?: string
    insurance_amount?: string
    expiration_date?: string
}
const SiteAddEditMachineInfo: FC<{ open: boolean, handleModel: any, siteInfo: { id: string, name: string }, id: string }> = ({ open, handleModel, siteInfo, id }) => {

    const style = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        bgcolor: 'background.paper',
        border: '1px solid #000',
        boxShadow: 24,
        borderRadius: 2,
        p: 2,
    };
    const intialState = {
        site_id: siteInfo,
        machine_type: "",
        company: "",
        machine_model: "",
        number_plate: '',
        purchase_date: "",
        expiration_date: "",
        manufacturing_date: "",
        measurement_unit: "",
        tank_capacity: "",
        insurance_amount: "",
        volume_capacity: "",
        aadhar_card: null,
        aadhar_card_url: "",
        isSiteChange: false,
        file_uploader: null,
        is_rented: false,
        rented_amount: 0,
        security_amount: 0,
        vehicle_paper: [],
        vehicle_paper_url: [],
        insurance: [],
        images: [],
        image_url: [],
        insurance_url: [],
        vehicle_rc: [],
        vehicle_rc_url: [],
        loan_paper: [],
        loan_paper_url: [],
        urls: [],
        deleted_images: [],
        error: "",
        financer_name: "",
        monthly_installment: 0,
        is_loanable: false,
        loan_duration: 0,

    }
    let dispatch = useDispatch();
    const [formData, setFormData] = useState<UserState>(intialState);
    const [formErrors, setFormErrors] = useState<FormErrors>({});
    const [orgId, setOrgId] = useState<string>("");
    const userLoginDetail = useSelector((state: RootState) => state.auth);
    const machineTypeData = useGetMachineByOrgIdQuery(orgId, { skip: !orgId });

    const { data: MachineInfoData } = useGetMachineInfoByIdQuery(id, {
        skip: !id,
    });



    const [createMachineInfo, result] = useCreateMachineInfoMutation();

    if (result.isSuccess && result.data.status === 200) {
        handleModel(false);
    }
    const validate = (formData: UserState) => {
        const errors: Partial<UserState> = {};

        if (!formData.company.trim()) {
            errors.company = "Company is required!";
        }
        if (!formData.number_plate.trim()) {
            errors.number_plate = "Number Plate is required!";
        }
        if (!formData.purchase_date) {
            errors.purchase_date = 'Purchase date is required';
        }

        if (!formData.manufacturing_date) {
            errors.manufacturing_date = 'Manufacturing date is required';
        }

        if (formData.purchase_date && formData.manufacturing_date) {
            const manufacturingDate = new Date(formData.manufacturing_date);
            const purchaseDate = new Date(formData.purchase_date);

            if (manufacturingDate > purchaseDate) {
                errors.purchase_date = 'Purchase date must be greater than manufacturing date';
            }
        }
        const today = new Date();

        if (!formData.expiration_date) {
            errors.expiration_date = 'Expiration date is required';
        } else {
            const expirationDate = new Date(formData.expiration_date);

            if (expirationDate <= today) {
                errors.expiration_date = 'Please select a future date for expiration';
            }
        }
        if (!formData.tank_capacity) {
            errors.tank_capacity = "Tank Capacity is required!";
        }
        return errors;
    };
 
    useEffect(() => {
        if (userLoginDetail?.user?.organization_id) {
            setOrgId(userLoginDetail?.user?.organization_id);

        } else {
            setOrgId(localStorage.getItem('orgId') ?? '');

        }

    }, [userLoginDetail?.user?.organization_id])
    const machineNamesData = useGetMachineByOrgIdQuery(orgId, { skip: !orgId });

    const handleSubmit = (e: any) => {
        e.preventDefault();
        dispatch(setError([]));
        const purchaseDateTimestamp = Math.floor(new Date(formData.purchase_date)?.getTime());
        let formattedData = {
            machine_type: formData?.machine_id ?? "",
            company: formData?.company ?? "",
            machine_model: formData?.machine_model ?? "",
            number_plate: formData?.number_plate.toUpperCase() ?? "",
            purchase_date: purchaseDateTimestamp ?? "",
            site_id: formData.site_id.id !== '' ? formData.site_id.id : "",
            is_rented: formData?.is_rented ?? false,
            is_loanable: formData?.is_loanable ?? false,
            monthly_installment: formData?.monthly_installment ?? "",
            financer_name: formData?.financer_name ?? "",
            loan_duration: formData?.loan_duration ?? 0,
            rented_amount: formData?.rented_amount ?? 0,
            security_amount: formData?.security_amount ?? 0,
            volume_capacity: Number(formData?.volume_capacity),
            tank_capacity: Number(formData?.tank_capacity),
            org_id: orgId ?? "",
            measurement_unit: formData?.measurement_unit ?? ""
        }

        createMachineInfo(formattedData);
    };
    const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, checked } = e.target;

        if (name === 'is_rented' && checked) {
            setFormData((prevState) => ({
                ...prevState,
                is_rented: true,
                is_loanable: false,
            }));
        } else if (name === 'is_loanable' && checked) {
            setFormData((prevState) => ({
                ...prevState,
                is_rented: false,
                is_loanable: true,
            }));
        } else {
            setFormData((prevState) => ({
                ...prevState,
                [name]: checked,
            }));
        }
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | any>) => {
        e.preventDefault();
        const { name, value } = e.target;

        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: name === "monthly_installment" ? Number(value) : value.trimStart()
        }));

        const errors = validate({ ...formData, [name]: value });
        setFormErrors((prevErrors) => ({
            ...prevErrors,
            [name]: errors[name as keyof UserState]
        }));
    };

  

    const selectMachineTypeChange = (event: (SelectChangeEvent | any)) => {
        const selectedValue = event.target.value.name
        const machineDetails = machineTypeData?.data?.response.find((machine: { name: any; }) => machine.name === selectedValue);
        console.log(machineDetails.measurement_unit, "dsdas")
        let userData = MachineInfoData?.response?.machinetypeData

        if (userData?._id === event.target.value?._id) {
            setFormData({
                ...formData,
                machine_id: event.target.value as string,
                isMachineChange: false,
                volume_capacity: "",
                number_plate: "",
                tank_capacity: "",
                measurement_unit: machineDetails?.measurement_unit || ""

            });
        } else {
            setFormData({
                ...formData,
                machine_id: event.target.value as string,
                isMachineChange: true,
                volume_capacity: "",
                number_plate: "",
                tank_capacity: "",
                measurement_unit: machineDetails?.measurement_unit || ""

            });
        }
        console.log('during change', event.target.value);

    };

    return (
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
            >
                <Fade in={open}>
                    <Box sx={style} className='form-outers karuModal'>
                        <Typography id="transition-modal-title" variant='h5' className='inner-headings' component="h2">
                            {'Add Machinery'}
                        </Typography>
                        <HighlightOffIcon className="closeicon" onClick={() => handleModel(false)} />

                        <Box >
                            <Divider sx={{ marginTop: 2, marginBottom: 3 }} />
                            <form onSubmit={handleSubmit}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} md={6}>
                                        <Box sx={{ minWidth: 120 }}>
                                            <FormControl fullWidth size="small">
                                                <InputLabel id="demo-simple-select-label">Sites Names</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    value={formData.site_id}
                                                    label="Sites Name"
                                                    renderValue={() => formData.site_id?.name}
                                                >
                                                    <MenuItem value={siteInfo?.id}>{siteInfo?.name}</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <Box sx={{ minWidth: 120 }}>
                                            <FormControl fullWidth size="small">
                                                <InputLabel id="demo-simple-select-label">Machine Types*</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    value={formData.machine_id}
                                                    label="Machine Types"
                                                    required
                                                    onChange={selectMachineTypeChange}
                                                    renderValue={() => formData.machine_id?.name}
                                                >
                                                    {machineNamesData && machineNamesData?.data?.response && machineNamesData?.data?.response.length > 0 ? (
                                                        machineNamesData?.data?.response.map((item: any, index: number) => (
                                                            <MenuItem value={item} key={index}>{item?.name}</MenuItem>
                                                        ))
                                                    ) : (
                                                        <MenuItem disabled>No Data Available</MenuItem>
                                                    )}

                                                </Select>
                                            </FormControl>
                                        </Box>
                                    </Grid >
                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            required
                                            fullWidth
                                            id="company"
                                            label="Company"
                                            name="company"
                                            type="text"
                                            size='small'
                                            autoComplete="company"
                                            value={formData.company}
                                            onChange={handleChange}
                                            error={!!formErrors.company}
                                            helperText={formErrors.company}
                                        />
                                        <Error current_key={formData.company} />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            required
                                            fullWidth
                                            id="machine_model"
                                            label="Machine Model"
                                            name="machine_model"
                                            type="text"
                                            size='small'
                                            autoComplete="machine_model"
                                            value={formData.machine_model}
                                            onChange={handleChange}
                                            error={!!formErrors.machine_model}
                                            helperText={formErrors.machine_model}
                                        />

                                        <Error current_key={formData.machine_model} />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            fullWidth
                                            id="purchase_date"
                                            label="Purchase Date"
                                            name="purchase_date"
                                            className="calendradate"
                                            type="date"
                                            size='small'
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            required
                                            autoComplete="purchase_date"
                                            value={formData.purchase_date}
                                            onChange={handleChange}
                                            error={!!formErrors.purchase_date}
                                            helperText={formErrors.purchase_date}
                                        />
                                        <Error current_key={formData.purchase_date} />
                                    </Grid>

                                    <Grid item xs={12} md={6}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={formData.is_rented}
                                                    onChange={handleCheckboxChange}
                                                    name="is_rented"
                                                />
                                            }
                                            label={<Typography sx={{ color: 'white' }}>Rented Machine</Typography>}
                                        />
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={formData.is_loanable}
                                                    onChange={handleCheckboxChange}
                                                    name="is_loanable"
                                                />
                                            }
                                            label={<Typography sx={{ color: 'white' }}>Loanable</Typography>}
                                        />
                                    </Grid>
                                    {formData?.is_rented &&
                                        <div className="isRented" >
                                            <Grid container spacing={3}>
                                                {formData?.is_rented &&
                                                    <Grid item xs={12} md={6}>
                                                        <TextField
                                                            fullWidth
                                                            required
                                                            id="rented_amount"
                                                            label="Rented Amount"
                                                            name="rented_amount"
                                                            type="number"
                                                            size='small'
                                                            inputProps={{
                                                                min: 1,
                                                            }}
                                                            autoComplete="rented_amount"
                                                            value={formData.rented_amount}
                                                            onChange={handleChange}
                                                        />
                                                        <Error current_key="rented_amount" />
                                                    </Grid>
                                                }
                                                {formData?.is_rented &&
                                                    <Grid item xs={12} md={6}>
                                                        <TextField
                                                            fullWidth
                                                            id="security_amount"
                                                            label="Security Amount"
                                                            name="security_amount"
                                                            type="number"
                                                            size='small'
                                                            inputProps={{
                                                                min: 1,
                                                            }}
                                                            autoComplete="security_amount"
                                                            value={formData.security_amount}
                                                            onChange={handleChange}
                                                        />
                                                        <Error current_key="security_amount" />
                                                    </Grid>
                                                }
                                            </Grid>
                                        </div>
                                    }
                                    {formData?.is_loanable &&

                                        <div className="isLoanable">
                                            <Grid container spacing={3}>
                                                {formData?.is_loanable &&
                                                    <Grid item xs={12} md={4}>
                                                        <TextField
                                                            fullWidth
                                                            id="financer_name"
                                                            label="Financer Name"
                                                            name="financer_name"
                                                            type="text"
                                                            size='small'
                                                            inputProps={{
                                                                min: 1,
                                                            }}
                                                            autoComplete="financer_name"
                                                            value={formData.financer_name}
                                                            onChange={handleChange}
                                                        />
                                                        <Error current_key="financer_name" />
                                                    </Grid>
                                                }
                                                {formData?.is_loanable &&
                                                    <Grid item xs={12} md={4}>
                                                        <TextField
                                                            fullWidth
                                                            id="monthly_installment"
                                                            label="Montly Installment"
                                                            name="monthly_installment"
                                                            type="text"
                                                            size='small'
                                                            inputProps={{
                                                                min: 1,
                                                            }}
                                                            autoComplete="monthly_installment"
                                                            value={formData.monthly_installment}
                                                            onChange={handleChange}
                                                        />
                                                        <Error current_key="monthly_installment" />
                                                    </Grid>
                                                }
                                                {formData?.is_loanable &&
                                                    <Grid item xs={12} md={4}>
                                                        <TextField
                                                            fullWidth
                                                            id="loan_duration"
                                                            label="Loan Duration"
                                                            name="loan_duration"
                                                            type="number"
                                                            size='small'
                                                            inputProps={{
                                                                min: 1,
                                                            }}
                                                            autoComplete="loan_duration"
                                                            value={formData.loan_duration}
                                                            onChange={handleChange}
                                                        />
                                                        <Error current_key="loan_duration" />
                                                    </Grid>
                                                }
                                            </Grid>
                                        </div>
                                    }
                                    <ReusableAddEditMachineInfo
                                        machineType={formData.machine_id}
                                        formData={formData}
                                        handleChange={handleChange}
                                        formErrors={formErrors}
                                    />
                                    <Grid item xs={12}>
                                        <Button
                                            type="submit"
                                            variant="contained"
                                            color="primary"
                                            className="save-btn"
                                            onSubmit={handleSubmit}
                                            disabled={Object.values(formErrors).some(error => !!error)}
                                            style={{ marginRight: '10px' }}
                                        >
                                            Submit
                                        </Button>
                                        <Button
                                            variant="contained"
                                            color="secondary"
                                            className="close-btn"
                                            onClick={() => handleModel(false)}
                                        >
                                            Cancel
                                        </Button>
                                    </Grid>
                                </Grid>
                            </form>
                        </Box>
                    </Box>
                </Fade>
            </Modal>
    );
};

export default memo(SiteAddEditMachineInfo);
