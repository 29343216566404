import { Box, Button, FormControl, InputLabel, MenuItem, Paper, Select, SelectChangeEvent, StyledEngineProvider, Table, TableBody, TableContainer, TableHead, TablePagination, TableRow, TextField, Toolbar, Tooltip, Typography } from '@mui/material'
import React, { FC, useCallback, useState } from 'react'
import { useDailyLogListQuery, useGetSitesByIdQuery } from '../../../../redux-services';
import { TableHeadElement } from '../../../../components/TableBody/TableHeadElement';
import { TableBodyElement } from '../../../../components/TableBody/TableBodyElement';
import { ColumnsType, Order, PageMeta, SortingData } from '../../../../types/CommonTypes';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import EditAttendance from './EditAttendance';
import EmpMarkAttendance from './EmpMarkAttendance';
import DuplicateAttendance from './DuplicateAttendance';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store/store';

export interface UserState {
    site_id: any;
    shift_id: any;
    date: string | Record<string, any>;
    select_date: string;
}

const tableColumns: ColumnsType[] = [
    {
        id: "id",
        sort: false,
        label: "S.No.",
    },
    {
        id: "employee_name",
        sort: true,
        label: "Employee Name",
    },
    {
        id: "contact_no",
        sort: true,
        label: "Contact No",
    },
    {
        id: 'shift',
        sort: false,
        label: "Shift Name",
    },
    {
        id: "attendance_status",
        sort: false,
        label: "Attendance Status",
    },
    {
        id: "comment",
        sort: false,
        label: "Comment"
    },
    {
        id: "createdAt",
        sort: false,
        label: "Date"
    },
    {
        id: "action",
        sort: false,
        label: "Action",
        style: { width: "15%" },
    },
];

let initialOrder: string = process.env.REACT_APP_ORDER as string;

const TakeAttendance: FC<{ open: boolean, setOpenAttendanceModal: any, siteInfo: { id: string, name: string } }> = ({ open, setOpenAttendanceModal, siteInfo }) => {

    const initialState: PageMeta = {
        page: 1,
        per_page: Number(process.env.REACT_APP_PER_PAGE),
        searchText: "",
        order: process.env.REACT_APP_ORDER,
        order_by: process.env.REACT_APP_ORDER_BY,

    };
    const intialState = {
        site_id: siteInfo,
        shift_id: "",
        date: "",
        select_date: "",
    }
    let { id: siteId } = useParams();

    const [formData, setFormData] = useState<UserState>(intialState);
    const [showEmployeeList, setShowEmployeeList] = useState(false);
    const { data: getData } = useGetSitesByIdQuery(siteId);



    let navigate = useNavigate();
    let query = useLocation();

    const { id } = useParams();

    const [openEdit, setOpenEdit] = useState<boolean>(false);
    const [editId, setEditId] = useState<string>('');
    const [page, setPage] = useState<number>(1);
    const [orderBy, setOrderBy] = useState<keyof SortingData>(
        process.env.REACT_APP_ORDER_BY as keyof SortingData
    );
    const [order, setOrder] = useState<Order>(initialOrder as Order);
    const [rowsPerPage, setRowsPerPage] = useState<number>(
        initialState.per_page as number
    );
    const [userList, setUserList] = useState<PageMeta>(initialState);
    const [showDuplicateModal, setShowDuplicateModal] = useState(false);

    const siteOrgId = useSelector((state: RootState) => state.auth.siteOrgId);

    const {data } = useDailyLogListQuery({
        per_page: userList.per_page ?? rowsPerPage,
        page: query.state ?? userList.page,
        order: userList.order ?? order,
        order_by: userList.order_by ?? orderBy,
        searchText: userList.searchText ?? "",
        select_date: formData?.select_date ?? '',
        shift_id: formData?.shift_id?._id ?? '',
        id: id,
        organization_id: siteOrgId,
        site_id: siteInfo.id,
    });
   

    const handleRequestSort = (
        event: React.MouseEvent<unknown>,
        property: keyof SortingData
    ) => {
        const isAsc = orderBy === property && order === "asc";
        const newOrder = isAsc ? "desc" : "asc";
        setOrder(newOrder);
        setOrderBy(property);
        setUserList({
            ...userList,
            order: newOrder,
            order_by: property,
        });
    };

    const createSortHandler = (
        event: React.MouseEvent<unknown>,
        property: keyof SortingData
    ) => {
        if (property !== ("action" as keyof SortingData)) {
            handleRequestSort(event, property);
        }
    };

    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number
    ) => {
        setPage(newPage + 1);
        setUserList({ ...userList, page: newPage + 1 });

        if (query.state !== null) {
            navigate(query.pathname, {});
        }
    };
    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setUserList({
            ...userList,
            per_page: parseInt(event.target.value),
            page: 1,
        });
        setPage(1);
    };

    const getTodayDate = () => {
        const today = new Date();
        const yyyy = today.getFullYear();
        const mm = String(today.getMonth() + 1).padStart(2, '0');  
        const dd = String(today.getDate()).padStart(2, '0');
        return `${yyyy}-${mm}-${dd}`;
    };

    const handleDateChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | any>) => {
        e.preventDefault();
        const { name, value } = e.target;

        if (name === "date") {
            let inputDate = value;
            let date = new Date(inputDate);
            let currentTime = new Date();
            date.setHours(currentTime.getHours());
            date.setMinutes(currentTime.getMinutes());
            date.setSeconds(currentTime.getSeconds());
            date.setMilliseconds(currentTime.getMilliseconds());
            let unixTimeStamp = date.getTime();
            setFormData((prevFormData) => ({
                ...prevFormData,
                [name]: unixTimeStamp
            }));
        }
        else {
            setFormData((prevFormData) => ({
                ...prevFormData,
                [name]: value.trimStart()
            }));
        }
    };

    const handleShowEmployeeAttendanceModel = useCallback((isOpen: boolean) => {
        setShowEmployeeList(isOpen);
    }, []);

    const selectChange = (event: (SelectChangeEvent | any)) => {
        setFormData({
            ...formData,
            shift_id: event.target.value as string
        });
    };

    const handleMarkAttendance = () => {
        const today = new Date();

        const year = today.getFullYear();
        const month = String(today.getMonth() + 1).padStart(2, '0');
        const day = String(today.getDate()).padStart(2, '0');

        const formattedDate = `${year}-${month}-${day}`;
        setShowEmployeeList(true);
        setFormData({ ...formData, date: formattedDate });
    }

    const handleDuplicateAttendanceModel = useCallback((isOpen: boolean) => {
        setShowDuplicateModal(isOpen);
    }, []);

    const handleModel = useCallback((isOpen: boolean) => {
        setOpenEdit(isOpen);
    }, []);
    
    return (
        <>
            <Button
                variant="contained"
                color="primary"
                className="secondary-btn"
                style={{ maxWidth: '60px', maxHeight: '30px', minWidth: '30px', minHeight: '30px' }}
                onClick={() => setOpenAttendanceModal(false)}
            >
                <ArrowBackIcon />
            </Button>
            <div className='table'>
                <Paper className="table-outers mt-3">
                    <Toolbar style={{ display: "flex", justifyContent: "space-between" }}>
                        <Typography id="transition-modal-title" variant='h5' className='inner-headings' component="h5">
                            Explore Attendance
                        </Typography>

                        <div className="flex-boxinner" style={{ gap: "10px" }}>
                            <TextField
                                id="select_date"
                                name="select_date"
                                label="Select Date"
                                type="date"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                inputProps={{
                                    max: getTodayDate(),
                                }}
                                value={formData.select_date}
                                size='small'
                                onChange={handleDateChange}
                            />
                            <Box sx={{ minWidth: 150 }}>
                                <FormControl fullWidth size='small'>
                                    <InputLabel id="demo-simple-select-label">Select Shifts *</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={formData.shift_id !== '' ? formData.shift_id : "None"}
                                        label="Select Shifts"
                                        required
                                        onChange={selectChange}
                                        renderValue={() => formData.shift_id !== "" ? formData.shift_id?.name : "None"}
                                    >
                                        <MenuItem value={""}>None</MenuItem>
                                        {getData && getData?.response?.shiftNames?.map(
                                            (item: any, index: number) => (
                                                <MenuItem value={item} key={index}>{item?.name}</MenuItem>
                                            ))}
                                    </Select>
                                </FormControl>
                            </Box>
                            <div>
                                <Tooltip title={`Mark Fresh Attendance`} arrow className="me-1 mt-1">
                                    <Button style={{ height: "100%" }} className="secondary-btn" onClick={handleMarkAttendance} type="submit" variant="contained" color="primary">Mark Fresh Attendace</Button>
                                </Tooltip>
                            </div>
                            <div>
                                <Tooltip title={`Fetch With Previous`} arrow className="me-1 mt-1">
                                    <Button style={{ height: "100%" }} className="secondary-btn" onClick={() => setShowDuplicateModal(true)} type="submit" variant="contained" color="primary">Fetch With Previous</Button>
                                </Tooltip>
                            </div>
                        </div>
                    </Toolbar>
                    <StyledEngineProvider>
                        <TableContainer component={Paper} className="table-outers">
                            <Table
                                sx={{ minWidth: 650 }}
                                size="small" aria-label="a dense table"
                            >
                                <TableHead>
                                    <TableRow>
                                        <TableHeadElement
                                            setHead={tableColumns ? tableColumns : []}
                                            order={order}
                                            orderBy={orderBy}
                                            sortFn={(event: any, id: string) =>
                                                createSortHandler(event, id as keyof SortingData)
                                            }
                                        />
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableBodyElement
                                        selectedColumns={[
                                            "employee_name",
                                            "contact_no",
                                            "shift",
                                            "attendance_status",
                                            "comment",
                                            "createdAt",
                                        ]}
                                        setData={data?.response.data ? data?.response.data : []}
                                        editFn={(id: string) => {
                                            setOpenEdit(true);
                                            setEditId(id);
                                        }}
                                        pageData={{
                                            limit:
                                                rowsPerPage ?? Number(process.env.REACT_APP_PER_PAGE),
                                            page: data?.response.page as number,
                                        }}
                                    />
                                </TableBody>
                            </Table>
                            <TablePagination
                                component="div"
                                count={data?.response.total ?? 0}
                                page={data?.response.page ?? 0}
                                rowsPerPageOptions={[20, 50, 100]}
                                onPageChange={handleChangePage}
                                rowsPerPage={
                                    rowsPerPage ?? Number(process.env.REACT_APP_PER_PAGE)
                                }
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </TableContainer>
                    </StyledEngineProvider>
                </Paper>

                {openEdit &&
                    <EditAttendance
                        open={openEdit}
                        handleModel={handleModel}
                        siteInfo={siteInfo}
                        id={editId}
                    />
                }
                {showEmployeeList &&
                    <EmpMarkAttendance
                        open={showEmployeeList}
                        handleModel={handleShowEmployeeAttendanceModel}
                        siteInfo={siteInfo}
                    />
                }
                {showDuplicateModal &&
                    <DuplicateAttendance
                        open={showDuplicateModal}
                        handleModel={handleDuplicateAttendanceModel}
                        siteId={siteInfo.id ?? ''}
                        setAttendanceState={setFormData}
                    />
                }
            </div>
        </>
    )
}

export default TakeAttendance